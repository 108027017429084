import { useEffect, useRef, useState } from "react";
import Filter from "../Common/Filter.js";
import Footer from "../Common/Footer.js";
import Sidebar from "../Common/Sidebar.js";
import { fetchService } from "../Services/fetch.service.js";
import Loader from "../Common/Loader.js";

const Appliances = ({ activeButton, connectionSystem }) => {
    const [selectedRow, setSelectedRow] = useState();
    const [appliances, setAppliances] = useState([]);
    const [applianceDetail, setApplianceDetail] = useState();
    const [refresh, setRefresh] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [searchKey, setSearchKey] = useState('');

    const [filter, setFilter] = useState({
        all: true,
        disabled: false,
    });

    const handleClickAppliances = (data, index) => {
        setSelectedRow(index);
        setApplianceDetail(data);
    }

    useEffect(() => {
        const fetchAppliances = async () => {
            const response = await fetchService.appliance();
            setIsLoading(false);
            setAppliances(response);
        }
        if (connectionSystem === 'enable') {
            fetchAppliances();
        } else {
            setIsLoading(false)
        }
    }, [refresh, connectionSystem]);

    useEffect(() => {
        if (applianceDetail) {
            setAppliances(prev => {
                const temp = prev;
                temp[selectedRow] = applianceDetail;
                return [...temp];
            })
        }
    }, [applianceDetail, selectedRow])

    const tableRef = useRef();
    const scrollTable = (scrollOffset, direction) => {
        if (tableRef.current) {
            if (!direction) {
                tableRef.current.scrollTop += scrollOffset;
            } else {
                tableRef.current.scrollTop -= scrollOffset;
            }
        }

    };
    return (
        <div className="h-full">
            {
                isLoading && (
                    <Loader />
                )
            }
            <Filter title={"appliances"} filter={filter} setFilter={setFilter} setKeyword={setSearchKey}/>

            <div className="flex justify-between h-full shadow-bottom">
                <div className="overflow-auto w-[90%] h-full border-t border-black mt-2" ref={tableRef}>
                    <table className="table-fixed w-full">
                        <thead>
                            <tr>
                                <th className="text-center">Appliances</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Source</th>
                                <th className="w-[5%]"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                appliances.map((data, i) => (
                                    (searchKey ? (data.name.toLowerCase().includes(searchKey.toLowerCase())) : true ) && (filter.disabled ? data.available === false : true) && (
                                        <tr key={i} onClick={() => handleClickAppliances(data, i)} className={`${i === selectedRow ? 'bg-black hover:bg-black text-white' : ''}`}>
                                            <td className="lg:pl-10 md:pl-6 pl-2">{data.name}</td>
                                            <td className="text-center">{data.status}</td>
                                            <td>{data?.source}</td>
                                            <td className="h-[10px]">
                                                <div className={`w-6 mx-auto h-full ${data.available === true ? 'bg-green-500' : 'bg-red-500'}`}></div>
                                            </td>
                                        </tr>
                                    )
                                ))
                            }

                        </tbody>
                    </table>
                </div>

                <Sidebar activeButton={activeButton} onScroll={scrollTable} object={applianceDetail} setObject={setApplianceDetail} setRefresh={setRefresh} />
            </div>

            <Footer />
        </div>
    )
}
export default Appliances;