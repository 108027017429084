function getQuantity(items) {
    let isRemark = false;

    for (let i = 0; i < items.length; i++) {
        if (items[i].type === 'remarks') {
            isRemark = true;
        }
    }

    if (isRemark) {
        return (items?.length - 1);
    } else {
        return items?.length;
    }
}
export default getQuantity;