import { useEffect, useRef, useState } from "react";
import Filter from "../Common/Filter.js";
import Footer from "../Common/Footer.js";
import Sidebar from "../Common/Sidebar.js";
import { fetchService } from "../Services/fetch.service.js";
import Loader from "../Common/Loader.js";

const Availability = ({ activeButton, connectionSystem }) => {
    const [selectedRow, setSelectedRow] = useState();
    const [products, setProducts] = useState([]);
    const [productDetail, setProductDetail] = useState();
    const [refresh, setRefresh] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const [searchkey, setSearchKey] = useState('');
    const [filter, setFilter] = useState({
        all: true,
        disabled: false,
        mdisabled: false
    });

    const handleclickAvailability = (product, index) => {
        setSelectedRow(index);
        setProductDetail(product);
    }

    useEffect(() => {
        const fetchProducts = async () => {
            const response = await fetchService.availability();
            setIsLoading(false);
            setProducts(response.products);
        }
        if(connectionSystem === 'enable') {
            fetchProducts();
        } else {
            setIsLoading(false)
        }
    }, [refresh, connectionSystem]);

    const tableRef = useRef();
    const scrollTable = (scrollOffset, direction) => {
        if (tableRef.current) {
            if (!direction) {
                tableRef.current.scrollTop += scrollOffset;
            } else {
                tableRef.current.scrollTop -= scrollOffset;
            }
        }

    };

    useEffect(() => {
        if (productDetail) {
            setProducts(prev=>{
                const temp = prev;
                temp[selectedRow] = productDetail;
                return [...temp];
            })
        }
    }, [productDetail, selectedRow])

    return (
        <div className="h-full">
            {
                isLoading && (
                    <Loader/>
                )
            }
            <Filter title={"availability"} setKeyword={setSearchKey} keyboard={searchkey} filter={filter} setFilter={setFilter} />

            <div className="flex justify-between h-full shadow-bottom">
                <div className="overflow-auto w-[90%] h-full border-t border-black mt-2" ref={tableRef}>
                    <table className="table-fixed w-full">
                        <thead>
                            <tr>
                                <th className="text-center">Item</th>
                                <th className="text-center">Status</th>
                                <th className="w-[5%]"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                products?.map((product, i) => (
                                    (searchkey ? (product.name.toLowerCase().includes(searchkey.toLowerCase())) : true) && (filter.disabled ? product.available === false : true) && (
                                            <tr key={"availability-" + i} className={`${i === selectedRow ? 'bg-black hover:bg-black text-white' : ''}`} onClick={() => { handleclickAvailability(product, i) }}>
                                                <td className="lg:pl-10 md:pl-6 pl-2">{product.name}</td>
                                                <td>{product.status}</td>
                                                <td className="h-[1px] bg-white">
                                                    <div className={`${product.available === true ? 'bg-green-400' : 'bg-red-500'} w-6 mx-auto h-full`}></div>
                                                </td>
                                            </tr>
                                    )
                                ))
                            }

                        </tbody>
                    </table>
                </div>

                <Sidebar activeButton={activeButton} onScroll={scrollTable} object={productDetail} setObject={setProductDetail} setRefresh={setRefresh}/>
            </div>

            <Footer />
        </div>
    )
}

export default Availability;