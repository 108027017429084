import { useCallback, useEffect, useState } from "react";
import Header from "./Common/Header.js";
import Orders from "./Components/Orders.js";
import Inventory from "./Components/Inventory.js";
import Availability from "./Components/Availability.js";
import Appliances from "./Components/Appliances.js";
import OrderHistory from "./Components/OrderHistory.js";
import Footer from "./Common/Footer.js";
import {fetchService} from "./Services/fetch.service.js";
import getQuantity from "./Utils/getQuantity.js";
import getCompleted from "./Utils/getCompleted.js";
import Auth from "./Modals/Auth.js";
import { CookiesProvider, useCookies } from "react-cookie";
import Loader from "./Common/Loader.js";
import parseCookieValue from "./Utils/parseCookieValue.js";
import { act } from "react";
import { storageService } from "./Services/localStorage.service.js";

function App() {
  const [openAuth, setOpenAuth] = useState();
  const [cookies, setCookies, removeCookie] = useCookies(["user"]);

  const [activeButton, setActiveButton] = useState("order");
  const [total, setTotal] = useState({
    app: 0,
    dlvy: 0,
    local: 0,
  });

  const [connectionSystem, setConnectionSystem] = useState("enable"); //enable, break, disable
  const [openSocket, setOpenSocket] = useState(true);

  const [orders, setOrders] = useState([]);
  const [newSyncOrders, setNewSyncOrders] = useState([]);
  const [newOrder, setNewOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const baseUrl = process.env.REACT_APP_SOCKET;

  const checkInternet = useCallback(async () => {

    const timer = setInterval(async () => {
      const response = await fetchService.internetConnection();
      if (response) {
        setConnectionSystem("enable");
      } else {
        setConnectionSystem("disable");
      }
    }, 5000)

    return () => clearInterval(timer)
      
  }, []);

  useEffect(() => {
    if(!openSocket){
      checkInternet();
    }
  }, [openSocket, checkInternet])

  const handleLogin = (user) => {
    setCookies("user", user, { path: "/" });
  };

  const handleLogout = (user) => {
    removeCookie(user);
  };

  // Confirm cookie data is right
  useEffect(() => {
    const checkAuth = async () => {
      const payload = {
        su: cookies.user.username,
        sp: cookies.user.token,
      };
      const result = await fetchService.login_initial(payload);

      if (result) {
        setIsLoading(true);
        setOpenAuth(false);
      } else {
        setOpenAuth(true);
      }
    };

    if (cookies.user) {
      checkAuth();
    } else {
      setOpenAuth(true);
    }
  }, [cookies]);

  const fetchOrderLive = useCallback(async () => {
    const userCookieData = parseCookieValue("user");
    if (userCookieData) {
      const payload = {
        su: cookies?.user.username,
        sp: cookies?.user.token,
      };
      const response = await fetchService.orderLive(payload);

      if (response) {
        setIsLoading(false);
        const localstorage = storageService.read();

        localstorage.forEach((item) => {
          const index = response.findIndex((element) => element.id === item.id);
          if(index !== -1) {
            response[index] = item;
          }
        })
        setOrders(response);
      } else {
        setIsLoading(false)
      }
    }
  }, [cookies]);

  useEffect(() => {
    if (!openAuth) {
      if (connectionSystem === "enable") {
        setIsLoading(true)
        fetchOrderLive();
      } else {
        setIsLoading(false)
      }
      setIsLoading(false)
    }
  }, [connectionSystem, openAuth, fetchOrderLive]);

  useEffect(() => {
    if (newSyncOrders.length) {
      console.log(newSyncOrders);
      setOrders((prevItem) => [...prevItem, newSyncOrders]);
    }
  }, [newSyncOrders]);

  useEffect(() => {
    if (newOrder) {
      setOrders((prevItem) => [...prevItem, newOrder]);
    }
  }, [newOrder]);

  useEffect(() => {
    // headers total info
    const temp = {
      app: 0,
      dlvy: 0,
      local: 0,
    };

    orders?.forEach((item) => {
      const quantity = getQuantity(item.rows);
      const completed = getCompleted(item.rows);
      if (quantity !== completed) {
        switch (item.type) {
          case "app":
            temp.app++;
            break;
          case "delivery":
            temp.dlvy++;
            break;
          case "local":
            temp.local++;
            break;
          default:
            break;
        }
      }
    });
    setTotal(temp);
  }, [orders]);

  // useEffect(() => {
  //   let broadcast;

  //   const connectBroadcastWebSocket = () => {
  //     broadcast = new WebSocket(`${baseUrl}`);

  //     broadcast.onopen = () => {
  //       setConnectionSystem("enable");
  //       setOpenSocket(true);
  //       sendCommand({
  //         version: "v1",
  //         type: "MESSAGE_BROADCAST",
  //         data: "An example of message",
  //       });
  //       sendCommand({ version: "v1", type: "SYNC_ORDERS", data: "11" });
  //       sendCommand({ version: "v1", type: "SET_POS_STORE_ID", data: "0003" });
  //     };

  //     broadcast.onmessage = (event) => {
  //       // console.log("Received message:", message.data);

  //       const message = JSON.parse(event.data);
  //       // Handle different types of messages
  //       switch (message.type) {
  //         case "MESSAGE_BROADCAST":
  //           console.log("Received message:", message.data);
  //           break;
  //         case "SYNC_ORDERS":
  //           console.log("New Order");
  //           setNewSyncOrders(message.data);
  //           break;
  //         case "SET_POS_STORE_ID":
  //           console.log("POS_STORE_ID:", message.data);
  //           break;
  //         default:
  //           // Handle other message types if needed
  //           break;
  //       }
  //     };

  //     broadcast.onerror = (error) => {
  //       // console.error('WebSocket error:', error);
  //       // act(() => {
  //         setOpenSocket(false);
  //       // })
        
  //       // setTimeout(connectBroadcastWebSocket, 10000); // Adjust the delay as needed
  //     };

  //     broadcast.onclose = () => {
  //       console.log("WebSocket connection closed");
  //       // act(() => {
  //         setIsLoading(false);
  //       // })
  //       setTimeout(connectBroadcastWebSocket, 10000); // Adjust the delay as needed
  //     };
  //   };

  //   const sendCommand = (command) => {
  //     if (broadcast && broadcast.readyState === WebSocket.OPEN) {
  //       broadcast.send(JSON.stringify(command));
  //       // console.log("Sent command:", command);
  //     } else {
  //       console.log("WebSocket connection is not open, command not sent");
  //     }
  //   };

  //   if (!openAuth) {
  //     connectBroadcastWebSocket();
  //     // Clean up function
  //     return () => {
  //       if (
  //         broadcast &&
  //         (broadcast.readyState === WebSocket.OPEN ||
  //           broadcast.readyState === WebSocket.CONNECTING)
  //       ) {
  //         broadcast.close();
  //       }
  //     };
  //   }
  // }, [baseUrl, openAuth]);

  return (
    <CookiesProvider>
      {isLoading && <Loader />}
      {!openAuth ? (
        <div className="h-[100vh] min-w-[500px] lg:pb-[220px] md:pb-[260px] pb-[300px]">
          <Header
            connectionSystem={connectionSystem}
            setConnectionSystem={setConnectionSystem}
            total={total}
            handleLogout={handleLogout}
          />

          <div className="flex mb-2">
            <button
              className={`lg:px-9 md:px-3 px-2 py-2 me-2 shadow-group rounded-sm bg-gray-100 ${
                activeButton === "order" ? "activeButton" : ""
              }`}
              onClick={() => setActiveButton("order")}
            >
              Orders
            </button>
            <button
              className={`lg:px-6 md:px-3 px-2 py-2 mx-2 shadow-group rounded-sm bg-gray-100 ${
                activeButton === "inventory" ? "activeButton" : ""
              }`}
              onClick={() => setActiveButton("inventory")}
            >
              Inventory
            </button>
            <button
              className={`lg:px-6 md:px-3 px-2 py-2 mx-2 shadow-group rounded-sm bg-gray-100 ${
                activeButton === "availability" ? "activeButton" : ""
              }`}
              onClick={() => setActiveButton("availability")}
            >
              Availability
            </button>
            <button
              className={`lg:px-6 md:px-3 px-2 py-2 mx-2 shadow-group rounded-sm bg-gray-100 ${
                activeButton === "appliances" ? "activeButton" : ""
              }`}
              onClick={() => setActiveButton("appliances")}
            >
              Appliances
            </button>
            <button
              className={`lg:px-6 md:px-3 px-2 py-2 mx-2 shadow-group rounded-sm bg-gray-100 ${
                activeButton === "orderhistory" ? "activeButton" : ""
              }`}
              onClick={() => setActiveButton("orderhistory")}
            >
              Orders History
            </button>
          </div>

          {activeButton === "order" && (
            <Orders
              activeButton={activeButton}
              setTotal={setTotal}
              orders={orders}
              setOrders={setOrders}
              setNewOrders={setNewOrder}
            />
          )}
          {activeButton === "inventory" && (
            <Inventory
              activeButton={activeButton}
              connectionSystem={connectionSystem}
            />
          )}
          {activeButton === "availability" && (
            <Availability
              activeButton={activeButton}
              connectionSystem={connectionSystem}
            />
          )}
          {activeButton === "appliances" && (
            <Appliances
              activeButton={activeButton}
              connectionSystem={connectionSystem}
            />
          )}
          {activeButton === "orderhistory" && (
            <OrderHistory
              activeButton={activeButton}
              setNewOrders={setNewOrder}
              connectionSystem={connectionSystem}
            />
          )}
          <Footer />
        </div>
      ) : (
        <Auth
          openAuth={openAuth}
          setOpenAuth={setOpenAuth}
          handleLogin={handleLogin}
        />
      )}
    </CookiesProvider>
  );
}

export default App;
