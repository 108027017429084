import { useRef } from "react";
import Panel from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css"

const Keyboard = ({ setKeyword }) => {
    const keyboard = useRef();

    const onKeyPress = (button) => {
        if (button === "{shift}" || button === "{lock}") {
            handleShift();
        }
    }

    const onChangeKeyboard = (input) => {
        setKeyword(input)
    }

    const handleShift = () => {
        const layout = keyboard.current.options.layoutName;
        const newLayout = layout === "default" ? "shift" : "default";
        keyboard.current.setOptions({
            layoutName: newLayout
        });
    };

    return (
        <>
            <div className="absolute left-0 bottom-10 z-10">
                <div className="w-screen mt-2">
                    <Panel
                        keyboardRef={r => (keyboard.current = r)}
                        layoutName="default"
                        onChange={onChangeKeyboard}
                        onKeyPress={onKeyPress}
                    />
                </div>
            </div>
        </>
    )
}
export default Keyboard;