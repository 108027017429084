import { fetchService } from "../Services/fetch.service.js";
import { useEffect, useState, useCallback, useRef } from "react";
import Password from "./Password.js";
import Keyboard from "react-simple-keyboard";
import Keyboards from "./Keyboard.js";
import Loader from "../Common/Loader.js";
import Warning from "./Warning.js";

const Adjust = ({ setOpen, items, setItems }) => {

    const [openPass, setOpenPass] = useState(false);
    const [isPassed, setIsPassed] = useState(false);
    const [openReason, setOpenReason] = useState(false);
    const [reason, setReason] = useState();
    const [openError, setOpenError] = useState(false);

    const [input, setInput] = useState(0);
    const keyboard = useRef();

    const handleConfirm = () => {
        setOpenReason(true);
    }

    const adjustAPI = useCallback(async () => {
        const payload = {
            id: items.id,
            params: {
                quantity: input,
                reason: reason
            }
        }
        const response = await fetchService.inventoryUpdate(payload);

        if (response) {
            setItems(prevItems => ({
                ...prevItems,
                "quantity": input
            }))
            setOpen(false);
        } else {
            // Error API
            setOpenError(true);
            setIsPassed(false)
        }
    }, [items, input, setItems, setOpen, reason])

    useEffect(() => {
        if (isPassed) {
            adjustAPI();
        }
    }, [isPassed, adjustAPI]);

    const onChange = (input) => {
        setInput(input);
    }

    const onChangeInput = (event) => {
        const input = event.target.value;
        setInput(input);
        keyboard.current.setInput(input);
    }

    const onKeyPress = (button) => {
        // console.log("Button pressed", button);
    }

    const keyboardLayout = {
        default: ["7 8 9 {bksp}", "4 5 6 -", "1 2 3 .", "0 {enter}"]
    }
    const display = {
        '{bksp}': "<",
        '{enter}': "< Enter"
    }

    return (
        isPassed ? (
            <Loader />
        ) : (
            <>
                <div className=" bg-black/75 h-screen w-screen absolute top-0 left-0 z-10">
                    {
                        openError && (
                            <Warning setOpen={setOpenError} content={"Error API"}/>
                        )
                    }
                    <div className="mx-auto mt-20 md:w-[50%] w-[70%] bg-white">
                        <div className="px-3 py-2 bg-[#979797] text-white text-center text-2xl font-semibold">
                            Enter Stock
                        </div>
                        <div className="m-10 pb-4 text-center font-bold">
                            <form className="col-span-2" onSubmit={(e) => {e.preventDefault(); handleConfirm()}}>
                                <p className="text-xl">Stock</p>
                                <input className="w-full my-4 py-1 outline-none text-lg text-center border border-gray-400" type="number"
                                    value={input} onChange={onChangeInput} autoComplete="new-password"
                                ></input>

                                <Keyboard
                                    keyboardRef={r => (keyboard.current = r)}
                                    layout={keyboardLayout}
                                    display={display}
                                    onChange={onChange}
                                    onKeyPress={onKeyPress}
                                />
                            </form>
                            <div className="w-full flex justify-between my-8">
                                <button className=" bg-black text-white text-xl shadow-bottom shadow-gray-400 click w-56 py-4"
                                    onClick={handleConfirm}>
                                    <p>Ok</p>
                                </button>
                                <button className="bg-zinc-200 shadow-bottom text-xl shadow-gray-400 click w-56 py-4"
                                    onClick={() => setOpen(false)}>
                                    <p>Cancel</p>
                                </button>
                            </div>
                        </div>  
                    </div>
                </div>
                {
                    openReason && (
                        <Keyboards setOpenKeyboard={setOpenReason} setReason={setReason} setOpenPass={setOpenPass} />
                    )
                }
                {
                    openPass && (
                        <Password setOpenPass={setOpenPass} setIsPassed={setIsPassed} setCancel={setOpenPass} />
                    )
                }
            </>
        )

    )
}

export default Adjust