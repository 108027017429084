const Information = ({ setOpenRequest, setOpenPass, title }) => {

    const handleRequest = () => {
        setOpenPass(true)
        setOpenRequest(false);
    }
    
    return (
        <>
            <div className="bg-[#4ABDFE] h-screen w-screen absolute top-0 left-0 z-10">
                <div className="mx-auto mt-24 md:w-[60%] lg:w-[50%] xl:w-[40%] w-[80%] bg-white shadow-group">
                    <div className='background-information w-full py-8 px-auto relative'>
                        <svg xmlns="http://www.w3.org/2000/svg" className='mx-auto' width="62" height="62" viewBox="0 0 128 128" fill="none">
                            <circle cx="64.0003" cy="64.0003" r="61.5003" fill="white" stroke="#2DB1FC" stroke-width="5"/>
                            <path d="M54.2361 33.9137C54.2361 28.1062 58.4803 23.9978 64.3801 23.9978C70.2799 23.9978 74.419 28.0935 74.419 33.9137C74.419 39.7212 70.293 43.7155 64.3801 43.7155C58.4803 43.7155 54.2361 39.7339 54.2361 33.9137ZM53.9996 104.314L53.9996 83.9243L57.6656 52.5916L70.293 52.5916L73.9591 83.9243L73.9591 104.314L53.9996 104.314Z" fill="#2DB1FC"/>
                        </svg>
                        <div className="px-3 py-2 text-2xl text-gray-700 font-medium text-center">
                            Information
                        </div>

                        <div className='w-full p-10 px-20 text-2xl text-center text-gray-700 absolute top-1/2'>
                            {   
                                (title.type === 'break') && 'Request a break?'
                            }
                            {
                                (title.type === 'ordering') && (title.enabled ? 'Enable' : 'Disable') + ' Online Orders?'
                            }
                            <div className='w-full flex justify-between'>
                                <button className='flex rounded-xl bg-[#4ABDFE] shadow-bottom click my-10 px-14 py-4 mx-auto text-white' onClick={handleRequest}>
                                    OK
                                </button>
                                <div className='flex-grow'></div>
                                <button className='flex rounded-xl bg-[#4ABDFE] shadow-bottom click my-10 px-14 py-4 mx-auto text-white' onClick={() => setOpenRequest(false)}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

        </>
    )
}
export default Information;