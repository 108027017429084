function getCompleted(items) {
    let numbers = 0;
    
    for (let i = 0; i < items.length; i++) {
        if (items[i].status === 'READY'){
            numbers++;
        }
    }
    return numbers
}
export default getCompleted;