import Filter from "../Common/Filter.js"
import Sidebar from "../Common/Sidebar.js"
import { useEffect, useRef, useState } from "react";
import getQuantity from "../Utils/getQuantity.js";
import getCompleted from "../Utils/getCompleted.js";
import React from "react";


const Orders = ({ activeButton, orders, setOrders, setNewOrders }) => {

    const [selectedRow, setSelectedRow] = useState(0);

    const [orderDetails, setOrderDetails] = useState();
    const [selectedOrderDetail, setSelectedOrderDetail] = useState(0);
    const [orderDetail, setOrderDetail] = useState();

    const [currentTime, setCurrentTime] = useState(new Date());

    const orderRef = useRef(null);
    const detailRef = useRef(null);

    const [searchkey, setSearchKey] = useState('');
    const [filter, setFilter] = useState({
        local: true,
        app: true,
        delivery: true,
        details: false,
        unprepared: true
    });

    const scrollDetailTable = (scrollOffset, direction) => {
        if (detailRef.current) {
            if (!direction) {
                detailRef.current.scrollTop += scrollOffset;
            } else {
                detailRef.current.scrollTop -= scrollOffset;
            }
        }
    };

    const handleOrder = (order, index) => {
        setSelectedRow(index);
        setOrderDetails(order.rows);
        setSelectedOrderDetail(0);
        setOrderDetail(order.rows[0]);
    }

    const handleOrderDetail = (data, index) => {
        setSelectedOrderDetail(index);
        setOrderDetail(data);
    }

    const getDelayTime = (currentTime, readyTime) => {
        const ready = new Date(readyTime);
        const dTime = currentTime - ready;

        const seconds = Math.floor(dTime / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        if (dTime > 0) {
            return [hours % 24, minutes % 60, seconds % 60].join(':');
        } else {
            return '';
        }
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);


    useEffect(() => {
        if (orderDetail) {
            setOrderDetails(prev => {
                const newData = [...prev];
                newData[selectedOrderDetail] = orderDetail;
                return newData
            });
        }
    }, [orderDetail, selectedOrderDetail]);

    useEffect(() => {
        if (orderDetails) {
            setOrders(prev => {
                const newData = [...prev];
                newData[selectedRow].rows = orderDetails;
                return newData
            })
        }
    }, [orderDetails, selectedRow, setOrders])

    return (
        <div className="h-full min-h-60">
            <Filter title={"order"} setKeyword={setSearchKey} keyboard={searchkey} filter={filter} setFilter={setFilter} />

            <div className="flex justify-between h-full mt-2">
                <div className="overflow-auto lg:text-base md:text-sm text-xs pr-4 h-full border-t border-r border-black" ref={orderRef} >
                    <table className="table-fixed w-full text-wrap border-spacing-y-5">
                        <thead>
                            <tr className="xl:text-xl lg:text-lg md:text-sm text-sm">
                                <th className="w-[15%] min-w-12">Order</th>
                                <th className="w-[18%] min-w-12">Placed at</th>
                                <th className="w-[18%] min-w-13">Deliver at</th>
                                <th className="w-[40%] min-w-12">Status</th>
                                <th className="w-[10%] min-w-12 text-center">Qty</th>
                                <th className="w-[10%] min-w-12 text-center">Done</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders?.map((order, i) => (
                                    <tr key={"order-" + i} className={`${i === selectedRow ? 'bg-black hover:bg-black text-white' : ''}`} onClick={() => { handleOrder(order, i) }}>
                                        {
                                            ((filter.local && (order.type === 'local')) || (filter.app && (order.type === 'app')) || (filter.delivery && (order.type === 'delivery'))) && (searchkey ? (!!order.customer_name.toLowerCase().includes(searchkey.toLowerCase()) || !!order.customer_phone.includes(searchkey) || !!order.order_number.toString().includes(searchkey) || !!order.customer_email.toLowerCase().includes(searchkey.toLowerCase())) : true) && (filter.unprepared ? (getQuantity(order.rows) !== getCompleted(order.rows)) : true) && (
                                                <>
                                                    <td ><span className={`px-2 ${(order.type === 'local') && 'local text-white'} ${(order.type === 'app') && 'app text-white'} ${(order.type === 'delivery') && 'delivery text-black'}`}>{order.order_number}</span></td>
                                                    <td>{order.placed_at}</td>
                                                    <td>{order.must_be_delivered_at}</td>
                                                    <td className={`${getDelayTime(currentTime, order.must_be_ready_at) ? 'text-red-500' : ''}`}>
                                                        {getDelayTime(currentTime, order.must_be_ready_at) ? ((order.type === "delivery") ? <>
                                                            <span className="bg-black text-white px-1">Uber</span> D {getDelayTime(currentTime, order.must_be_ready_at) + order.customer_name}
                                                        </> : `D ${getDelayTime(currentTime, order.must_be_ready_at) + order.customer_name}`) : order.customer_name}
                                                    </td>
                                                    <td className="text-center">{getQuantity(order.rows)}</td>
                                                    <td className="bg-white text-center"><div className={`${i === selectedRow ? 'bg-green-500':'bg-red-500'} lg:mx-5 md:mx-3 mx-2 text-white`}>{getCompleted(order.rows)}</div></td>
                                                </>
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                <div className="overflow-auto min-w-[30%] flex-grow px-4 h-full border-t border-black" ref={detailRef}>
                    <table className="w-full">
                        <thead>
                            <tr className="lg:text-xl md:text-base text-sm">
                                <th className="w-[90%] text-center">Item</th>
                                <th className="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderDetails?.map((orderDetail, i) => (
                                    <React.Fragment key={i}>
                                        {
                                            (filter.unprepared ? (orderDetail.status === 'PENDING') : true) && (
                                                <>
                                                    {
                                                        (orderDetail.type !== 'remarks') && (
                                                            <tr key={"order1-" + i} className={`${i === selectedOrderDetail ? 'bg-black hover:bg-black text-white' : ''}`} onClick={() => { handleOrderDetail(orderDetail, i) }}>
                                                                <td className="ps-2">{orderDetail.name}</td>
                                                                <td className="w-[1px] h-[1px] bg-white">
                                                                    <div className={`w-5 h-full mx-auto  ${orderDetail.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        ((filter.details === true) && orderDetail?.modifiers) && (
                                                            (orderDetail.modifiers.length) ? (
                                                                orderDetail.modifiers.map((item, j) => (
                                                                    <tr key={"order1-" + i + "-" + j} className= {`${i === selectedOrderDetail ? 'bg-yellow-200' : ''}`} onClick={() => { handleOrderDetail(orderDetail, i) }}>
                                                                        <td className="lg:ps-5 ps-3">{item.name}</td>
                                                                        <td className="w-[1px] h-[1px] bg-white">
                                                                            <div className={`w-5 h-full mx-auto  ${orderDetail.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr key={"order2-" + i} className={`${i === selectedOrderDetail ? 'bg-yellow-200' : ''}`} onClick={() => { handleOrderDetail(orderDetail, i) }}>
                                                                    <td className="lg:ps-5 ps-3">{orderDetail.modifiers.name}</td>
                                                                    <td className="w-[1px] h-[1px] bg-white">
                                                                        <div className={`w-5 h-full mx-auto ${orderDetail.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                                    </td>
                                                                </tr>
                                                            )

                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                </div>


                <Sidebar activeButton={activeButton} onScroll={scrollDetailTable} order={orders[selectedRow]} object={orderDetail} setObject={setOrderDetail} setFilter={setFilter} setNewOrders={setNewOrders} />
            </div>

        </div>

    )
}
export default Orders