import { useCallback, useEffect, useState } from "react";
import React from "react";
import { fetchService } from "../Services/fetch.service";
import Loader from "../Common/Loader";
import Password from "./Password";

const RemakeOrder = ({order, setOpenRemake, setOpenActions}) => {
    const [openPass, setOpenPass] = useState(false);
    const [isPassed, setIsPassed] = useState(false);
    const [itemIndex, setItemIndex] = useState(0);
    const [items, setItems] = useState([]);

    const [filter, setFilter] = useState({
        unprepared: true,
        detail: false
    });

    const handleFilter = (event) => {
        const { name, checked } = event.target;
        setFilter(prevFilter => ({
            ...prevFilter, [name]: checked
        }))
    }

    const remakeAPI = useCallback(async () => {
        const payload = {
            orderId: order.id,
            detailId: items[itemIndex].id,
            payload: items[itemIndex]
        }

        const response = await fetchService.orderRemake(payload);

        if(response) {
            console.log("Sucess ORDER Remake!!!");
            setOpenRemake(false);
            setOpenActions(false);
        } else {
            console.log("Error API");
            setIsPassed(false);
        }
    }, [items, itemIndex])

    const handleConfirm = () => {
        setOpenPass(true);
    }

    useEffect(() => {
        setItems(order.rows)
    }, [order]);

    useEffect(() => {
        if(isPassed) {
            remakeAPI()
        }
    }, [isPassed])

    return(
        <>
            {
                isPassed ? (
                    <Loader/>
                ) : (
                    <>
                        <div className="h-screen w-screen absolute top-0 left-0 z-10">
                            <div className="mx-auto mt-20 w-[70%] lg:w-[60%] h-[500px] bg-white">
                                <div className="px-3 py-1 bg-[#979797] text-center text-2xl text-white font-bold">
                                    Remake Order
                                </div>
                                <div className="p-4 w-full bg-white">
                                    <div className=" flex justify-between">
                                        <div className="grid grid-cols-6 w-[60%] xl:text-lg">
                                            <div className="col-span-2">Customer</div>
                                            <div className="col-span-4 font-bold">{order.customer_name}</div>

                                            <div className="col-span-2">Order</div>
                                            <div className="col-span-4 font-bold">{order.type} #{order.order_number}</div>

                                            <div className="col-span-2">Details</div>
                                            <div className="col-span-4 font-bold"></div>
                                        </div>

                                        <div className="xl:text-base text-xs">
                                            <div className="flex items-center m-2">
                                                <input type="checkbox" name="unprepared" className="checkbox border" checked={filter.unprepared} onChange={handleFilter}></input>
                                                <p className="px-1">Unprepared only</p>
                                            </div>
                                            <div className="flex items-center m-2">
                                                <input type="checkbox" name="detail" className="checkbox border" checked={filter.detail} onChange={handleFilter}></input>
                                                <p className="px-1">Show details</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Table */}
                                    <div className="flex w-full h-[380px] justify-between mt-3">
                                        <div className="w-full overflow-auto border-t border-black">
                                            <table className="table-fixed w-full">
                                                <thead>
                                                    <tr>
                                                        <th className="w-[20px]"></th>
                                                        <th className="w-[10%] text-center">Qty</th>
                                                        <th className="text-center">Item</th>
                                                        <th className="w-[20%] text-center">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-center">
                                                    {
                                                        items?.map((item, i) => (
                                                            <React.Fragment key={i}>
                                                                {
                                                                    (filter.unprepared ? (item?.status === 'PENDING') : true) && (
                                                                        <>
                                                                            {
                                                                                (item.type !== 'remarks') && (
                                                                                    <tr className={`${i === itemIndex ? 'bg-black hover:bg-black text-white' : ''}`} onClick={() => setItemIndex(i)}>
                                                                                        <td className="w-[20px]">
                                                                                            {
                                                                                                (i === itemIndex) && (
                                                                                                    <div>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                                                                                            <rect width="19.1293" height="17.3075" fill="#00A600"/>
                                                                                                            <path d="M4.09961 8.97091L7.11368 11.8403L15.4861 5.46387" stroke="white" strokeWidth="1.74572"/>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </td>
                                                                                        <td>{item.quantity}</td>
                                                                                        <td>{item.name}</td>
                                                                                        <td>
                                                                                            <div className={`w-[20px] h-[20px] mx-auto ${item.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                            {
                                                                                ((filter.detail) && item?.modifiers) && (
                                                                                    (item.modifiers.length) ? (
                                                                                        item.modifiers.map((sitem, j) => (
                                                                                            <tr key={i + "-" + j} className={`${i === itemIndex ? 'bg-yellow-200' : ''}`} onClick={() => { setItemIndex(i) }}>
                                                                                                <td ></td>
                                                                                                <td>{sitem.quantity}</td>
                                                                                                <td>{sitem.name}</td>
                                                                                                <td>
                                                                                                    <div className={`w-[20px] h-[20px] mx-auto ${item.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    ) : (
                                                                                        <tr tr key={"replace1-" + i} className={`${i === itemIndex ? 'bg-yellow-200' : ''}`} onClick={() => { setItemIndex(i) }}>
                                                                                            <td></td>
                                                                                            <td>{item.modifiers.quantity}</td>
                                                                                            <td>{item.modifiers.name}</td>
                                                                                            <td>
                                                                                                <div className={`w-[20px] h-[20px] mx-auto ${item.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </React.Fragment>
                                                            
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="w-full mt-2 text-center flex justify-between font-semibold text-lg">
                                        <button className="bg-black px-14 py-3 shadow-bottom click" onClick={handleConfirm}>
                                            <p className="text-white">Confirm</p>
                                        </button>
                                        <button className="bg-gray-200 px-14 py-3 shadow-bottom click" onClick={() => setOpenRemake(false)}>
                                            <p>Cancel</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            openPass && (
                                <Password setOpenPass={setOpenPass} setIsPassed={setIsPassed} setCancel={setOpenPass}/>
                            )
                        }
                    </>
                )
            }
        </>
    )
}

export default RemakeOrder;