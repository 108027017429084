import { act, useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css"
import trash from '../Assets/Bin.png';


const Keyboards = ({ setOpenKeyboard, setReason, setOpenPass, activeButton }) => {

    const [input, setInput] = useState('');
    const [keyboardKey, setKeyboardKey] = useState(0);
    const [openWarning, setOpenWarning] = useState(false)
    const keyboard = useRef();

    const handleRequest = () => {
        if(activeButton === 'appliances' && input === '') {
            setOpenWarning(true)
        } else {
            setReason(input);
            setOpenPass(true);
            setOpenKeyboard(false);
        }
    }

    const onChangeInput = (event) => {
        const input = event.target.value;
        setInput(input);
        keyboard.current.setInput(input);
    }

    const onKeyPress = (button) => {
        if (button === "{shift}" || button === "{lock}") {
            handleShift();
        }
    }

    const onChangeKeyboard = input => {
        setInput(input);
    }

    const handleShift = () => {
        const layout = keyboard.current.options.layoutName;
        const newLayout = layout === "default" ? "shift" : "default";
        keyboard.current.setOptions({
            layoutName: newLayout
        });
    };

    const handleDelete = () => {
        setInput("");  // Clear the input state
        setKeyboardKey(prevKey => prevKey + 1);
    }

    return (
        <>
            <div className="bg-black/75 h-screen w-screen absolute top-0 left-0 z-10">
                <div className="mx-auto mt-24 w-[60%] bg-white text-center">
                    <div className="px-3 py-3 bg-[#979797] text-white text-2xl font-bold">
                        Reason
                    </div>
                    <div className="md:p-10 p-4">
                        <div className="flex justify-between items-center">
                            <p className="text-lg text-start font-bold">Type a brief description
                            {
                                activeButton === 'appliances' && (
                                    <span className={`${openWarning ? 'text-red-500' : ''}`}> (You must fill text!!! )</span>
                                )
                            }
                            </p>
                            <button onClick={handleDelete}>
                                <img src={trash} width={"25px"} alt="trash"></img>
                            </button>
                        </div>

                        <div className="min-w-[80%] mt-2">
                            <textarea className="w-full outline-none border border-gray-400 my-2 px-4 text-lg resize-none"
                                value={input}
                                onChange={onChangeInput}
                                placeholder="Type..."
                            ></textarea>

                            <Keyboard
                                key={keyboardKey}
                                keyboardRef={r => (keyboard.current = r)}
                                layoutName="default"
                                onChange={onChangeKeyboard}
                                onKeyPress={onKeyPress}
                            />
                        </div>
                        <div className="flex justify-between w-full mt-8">
                            <button className="bg-black px-14 py-2 text-white shadow-bottom click"
                                onClick={handleRequest}>
                                <p>OK</p>
                            </button>
                            <div className="flex-grow"></div>
                            <button className="bg-zinc-200 px-14 py-2 shadow-bottom click" onClick={() => setOpenKeyboard(false)}>
                                <p>Cancel</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Keyboards;