import { fetchService } from "../Services/fetch.service.js";
import { useState } from "react";

const Alerts = ({ setOpenAlerts, alerts, setAlerts }) => {

    const [filter, setFilter] = useState({
        all: true,
        unread: false
    })

    const fetchAlerts = async () => {
        const response = await fetchService.alert();
        setAlerts(response);
    }
    const handleEvent = (event) => {
        const { name, checked } = event.target;
        if (name === "all") {
            if (checked) {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    [name]: checked,
                    "unread": false
                }))
            } else {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    [name]: checked,
                    "unread": true
                }))
            }
        }
        if (name === "unread") {
            if (checked) {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    [name]: checked,
                    "all": false
                }))
            } else {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    [name]: checked,
                    "all": true
                }))
            }
        }
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: checked
        }))
    }
    const handleButton = () => {
        fetchAlerts();
    }

    return (
        <>
            <div className=" bg-black/75 h-screen w-screen absolute top-0 left-0 z-10">
                <div className="mx-auto mt-20 w-[50%] bg-white">
                    <div className="px-3 py-3 bg-[#979797] text-white text-center text-lg font-bold">
                        Alerts
                    </div>
                    <div className="w-full flex items-center pt-6 px-4">
                        <div className="px-3">View</div>
                        <div className='flex items-center mr-5'>
                            <input type='checkbox' className='checkbox border border-black' name="all" checked={filter.all} onChange={handleEvent}></input>
                            <span className='mx-2'>All</span>
                        </div>
                        <div className='flex items-center mr-5'>
                            <input type='checkbox' className='checkbox border border-black' name="unread" checked={filter.unread} onChange={handleEvent}></input>
                            <span className='mx-2'>Unread</span>
                        </div>
                        <div className="grow"></div>
                        
                    </div>
                    <div className="flex min-h-64 px-4">
                        <div className="grow">
                            <table className="w-full mt-3">
                                <tbody>
                                    {
                                        alerts?.map((data, i) => (
                                            (filter.unread ? (!data?.read) : true) && (
                                                <tr key={i} className="border-b border-gray-300 py-2">
                                                    <td className="checkbox">
                                                        <div className={`${(data.type === 'error' && 'bg-red-500') || (data.type === 'info' && 'bg-blue-600') || (data.type === 'warning' && 'bg-yellow-400')} w-full h-full`}></div>
                                                    </td>
                                                    <td>{data.type}</td>
                                                    <td>{data.message}</td>
                                                </tr>
                                            )
                                        ))
                                    }
                                </tbody>

                            </table>
                        </div>
                    </div>
                    <div className="w-full px-4 py-10 flex justify-between">
                        <button className='bg-gray-200 px-6 py-1 shadow-group click' onClick={handleButton}>
                            <p className='px-2 text-xl'>Refresh</p>
                        </button>
                        <button className='bg-gray-200 px-6 py-1 shadow-group click' onClick={() => setOpenAlerts(false)}>
                            <p className='px-2 text-xl'>Cancel</p>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Alerts;