import { useEffect, useState, useCallback } from 'react';
import Information from './Information.js';
import Password from './Password.js';
import { fetchService } from '../Services/fetch.service.js';
import Loader from '../Common/Loader.js';

const Status = ({ setOpenStatus, connectionSystem, setConnectionSystem }) => {
    const [openRequest, setOpenRequest] = useState(false);
    const [title, setTitle] = useState({});

    const [openPass, setOpenPass] = useState(false);
    const [isPassed, setIsPassed] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleBreak = async () => {
        if (connectionSystem === 'enable') {
            setTitle({
                type: "break",
                enabled: true
            })
            setOpenRequest(true);
        }
    }

    const e_online = async () => {
        if (connectionSystem === 'enable') {
            setTitle({
                type: "ordering",
                enabled: false
            })
            setOpenRequest(true)

        } else {
            setIsLoading(true)
            const result = await changeStatus();
            if (result) {
                setConnectionSystem("enable")
            }
        }
    }
    const changeStatus = useCallback(async () => {
        const payload = {
            type: title.type,
            enabled: title.enabled
        }
        const response = await fetchService.systemStatus(payload);
        if (response) {
            setIsLoading(false)
            return response;
        } else {
            setIsLoading(false)
        }
    }, [title])

    useEffect(() => {

        const status = async () => {
            const result = await changeStatus();
            if (result) {
                if (title.type === 'ordering') {
                    setConnectionSystem('disable');
                }
                if (title.type === 'break') {
                    setConnectionSystem('break');
                }
            }
            setIsPassed(false);
        }

        if (isPassed) {
            setIsLoading(true);
            status();
        }
    }, [isPassed, changeStatus, setConnectionSystem, title])

    useEffect(() => {
        if (connectionSystem !== 'enable') {
            setTitle({
                type: 'ordering',
                enabled: true
            })
        }
    }, [connectionSystem])

    return (
        <>
            <div className=" bg-black/75 h-screen w-screen absolute top-0 left-0 z-10">
                {
                    isLoading && (
                        <Loader />
                    )
                }
                <div className="mx-auto mt-20 w-[40%] bg-white">
                    <div className="px-3 py-1 bg-[#979797] text-white text-center text-2xl font-bold">
                        Monitor Status
                    </div>
                    <div className="m-6">
                        <div className="px-2 py-2 grow font-bold grid md:grid-cols-2 grid-cols-1">
                            <div>
                                <p className='pb-1 flex justify-between'>General Status: {connectionSystem}
                                    
                                </p>
                                <p className='pb-1'>Online ordering</p>
                                <p className='pb-1'>Last Update</p>
                                <p className='pb-1'>Ordering Timetable</p>
                                <p className='pb-1'>Ordering Types Enabled</p>
                            </div>
                            <div className='text-end md:order-last order-first'>
                                <button className={`${(connectionSystem === 'enable' && 'bg-green-400') || (connectionSystem === 'disable' && 'bg-red-600') || (connectionSystem === 'break' && 'bg-yellow-300')} bg-green-600 text-white py-1 px-8 font-semibold shadow-group click`}>
                                    <span>Refresh status</span>
                                </button>
                            </div>
                        </div>
                        <div className='mx-6 text-lg'>
                            <button className="w-[100%] bg-black py-3 my-3 text-center text-white shadow-group click"
                                onClick={handleBreak}>
                                Give me a break
                            </button>
                            <button className="w-[100%] bg-gray-200 py-3 my-3 text-center shadow-group click"
                                onClick={e_online}
                            >
                                {
                                    connectionSystem === 'enable' ? "Disable Online Orders" : "Enable Online Orders"
                                }
                            </button>
                            <button className="w-[100%] bg-gray-200 py-3 px-auto my-3 text-center shadow-group click"
                                onClick={() => setOpenStatus(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                    
                </div>
            </div>
            {
                openRequest && (
                    <Information setOpenRequest={setOpenRequest} setOpenPass={setOpenPass} setIsPassed={setIsPassed} title={title} />
                )
            }
            {
                openPass && (
                    <Password setOpenPass={setOpenPass} setIsPassed={setIsPassed} setCancel={setOpenPass} />
                )
            }
        </>
    )
}
export default Status;