import { useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";
import parseCookieValue from "../Utils/parseCookieValue.js";
import { fetchService } from "../Services/fetch.service.js";
import Warning from "./Warning.js";

const Password = ({ setOpenPass, setIsPassed, setCancel }) => {
    const [input, setInput] = useState("");
    const [openError, setOpenError] = useState(false);
    const keyboard = useRef();

    const handlePass = async () => {
        // Get password from cookie
        const userCookieData = parseCookieValue('user');

        const payload = {
            sub_token: userCookieData.sub_token,
            input: input
        }
        const response = await fetchService.pincode(payload);

        if (response) {
            setIsPassed(true);
            setOpenPass(false);
        } else {
            //Password is wrong
            setOpenError(true);
        }
    }

    const onChange = (input) => {
        setInput(input);
    }

    const onChangeInput = (event) => {
        const input = event.target.value;
        setInput(input);
        keyboard.current.setInput(input);
    }

    const onKeyPress = (button) => {
        // console.log("Button pressed", button);
    }

    const keyboardLayout = {
        default: ["7 8 9 {bksp}", "4 5 6 -", "1 2 3 .", "0 {enter}"]
    }
    const display = {
        '{bksp}': "<",
        '{enter}': "< Enter"
    }
    const buttonTheme = [
        {
            class: "buttonTheme",
            Buttons: "7 8 9 {bksp}"
        }
    ]

    return (
        <>
            <div className=" bg-black/75 h-screen w-screen absolute top-0 left-0 z-10">
                {
                    openError && (
                        <Warning setOpen={setOpenError} content={"Password is wrong"} />
                    )
                }
                <div className="mx-auto mt-24 md:w-[60%] lg:w-[50%] xl:w-[40%] w-[80%] bg-white">
                    <div className="px-3 py-3 bg-[#979797] text-white text-2xl text-center font-bold">
                        Enter password
                    </div>
                    <div className="m-10 text-center font-bold">
                        <form className="col-span-2" onSubmit={(e) => {e.preventDefault(); handlePass()}}>
                            <p className="text-xl">Password</p>
                            <input className="w-full my-4 py-1 outline-none text-lg text-center border border-gray-400" type="password"
                                value={input} onChange={onChangeInput} autoComplete="new-password"
                            ></input>

                            <Keyboard
                                keyboardRef={r => (keyboard.current = r)}
                                // theme="hg-theme-default hg-layout-default buttonTheme"
                                layout={keyboardLayout}
                                display={display}
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                                // buttonTheme={buttonTheme}
                            />
                        </form>
                        <div className="w-full flex justify-between my-8">
                            <button className=" bg-black text-white text-xl shadow-bottom shadow-gray-400 click w-56 py-4"
                                onClick={handlePass}>
                                <p>Ok</p>
                            </button>
                            <button className="bg-zinc-200 shadow-bottom text-xl shadow-gray-400 click w-56 py-4"
                                onClick={() => { setCancel(false) }}>
                                <p>Cancel</p>
                            </button>
                        </div>
                        
                    </div>
                    <div className="col-span-1 flex justify-between w-full">
                            
                        </div>
                </div>
            </div>
        </>
    )
}
export default Password;