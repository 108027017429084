import { useEffect, useState, useCallback } from "react"
import Actions from '../Modals/Actions.js';
import Print from '../Modals/Print.js';
import Keyboard from '../Modals/Keyboard.js';
import ProductAvailability from "../Modals/ProductAvailability.js";
import { storageService } from "../Services/localStorage.service.js";
import Adjust from "../Modals/Adjust.js";
import Password from "../Modals/Password.js";
import Warning from "../Modals/Warning.js";
import { fetchService } from "../Services/fetch.service.js";
import Loader from "./Loader.js";
import getQuantity from "../Utils/getQuantity.js";
import getCompleted from "../Utils/getCompleted.js";

const Pattern = ({ img, title, object, setObject, order, setRefresh, activeButton, setNewOrders }) => {
    const [openActions, setOpenActions] = useState(false);

    const [openAdjust, setOpenAdjust] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);

    const [openKeyboard, setOpenKeyboard] = useState(false);
    const [openPass, setOpenPass] = useState(false);
    const [isPassed, setIsPassed] = useState(false);
    const [reason, setReason] = useState('');
    const [openError, setOpenError] = useState(false); // in case enable to enabled object
    const [content, setContent] = useState('')

    const [name, setName] = useState(title);
    const [currentStorage, setCurrentStorage] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        !!object && (
            (title === 'Ready') && (
                (object.status === 'PENDING') ? (
                    setName('Ready')
                ) : (
                    setName("Undo")
                )
            )
        )
        setCurrentStorage(storageService.read())
    }, [object, title]);

    const storage = (status) => {
        const orderItemIndex = order?.rows.findIndex(obj => obj === object.id);
        const orderTemp = order;

        orderTemp.rows[orderItemIndex] = object
        orderTemp.rows[orderItemIndex].status = status;

        const storageIndex = currentStorage.findIndex(obj => obj.id === order.id);
        const storage = currentStorage;

        if (storageIndex === -1) {
            storage[storage.length] = orderTemp;
        } else {
            storage[storageIndex] = orderTemp;
        }

        storageService.save(storage);
    }

    const handleClick = async (condition) => {
        if (condition === 'Ready') {
            if (!!object) {
                setName('Undo');
                
                const quantity = getQuantity(order.rows);
                const completed = getCompleted(order.rows);

                if(quantity === completed + 1) {
                    const timer = setTimeout(async () => {
                        const localstorage = storageService.read();
                        const temp = localstorage.find((item) => item.id === order.id);
                        const index = localstorage.findIndex((item) => item.id === order.id)
                        if(!!temp) {
                            if(getQuantity(temp.rows) === getCompleted(temp.rows)) {
                                const response = await fetchService.orderCompleted(temp.id);
                                if(response) {
                                    localstorage.splice(index, 1)
                                    storageService.remove(localstorage);
                                    console.log("Order Completed")
                                }
                            }
                        }
                    }, 30000);

                }

                setObject(prevObject => ({
                    ...prevObject, "status": "READY"
                }));
                
                //LocalStorage
                storage("READY");
            }
        }
        if (condition === 'Undo') {
            const localstorage = storageService.read();
            const temp = localstorage.find((item) => item.id === order.id);

            const quantity = getQuantity(order.rows);
            const completed = getCompleted(order.rows);

            if((quantity === completed) && !temp) {
                setName('Undo');
            } else {
                setName('Ready');

                storage("PENDING")
            }

        }
        if (condition === "Action") {
            if (object) {
                setOpenActions(true);
            }
        }
        if (condition === "Adjust") {
            if (object) {
                setOpenAdjust(true);
            }
        }
        if (condition === "View") {
            if (object) {
                setOpenView(true)
            }
        }
        if (condition === "Reprint") {
            if(object) {
               setOpenPrint(true); 
            }
        }
        if (condition === "Enable") {
            if (object) {
                if (object.available) {
                    setContent(`Impossible to enable ${activeButton} that is already enabled`)
                    setOpenError(true);
                } else {
                    setOpenKeyboard(true);
                }
            }
        }
        if (condition === "Disable") {
            if (object) {
                if (!object.available) {
                    setContent(`Impossible to disable ${activeButton} that is already disabled`)
                    setOpenError(true);
                } else {
                    setOpenKeyboard(true)
                }
            }
        }
        if (condition === "Refresh") {
            setRefresh(refresh => !refresh)
        }
    }

    const appliances_E_D_API = useCallback(async (title) => {

        const payload = {
            id: object.id,
            params: {
                enabled: title === 'Enable' ? true : false,
                status: reason
            }
        }
        const response = await fetchService.applianceStatus(payload);

        if (response) {
            setObject(prevItem => ({
                ...prevItem,
                "available": title === 'Enable' ? true : false,
                "status": reason
            }))
            setIsLoading(false)
        } else {
            setContent('Error API')
            setOpenError(true);
            setIsLoading(false)
        }
    }, [object, reason, setObject])

    const availability_E_D_API = useCallback(async (title) => {
        const payload = {
            id: object.id,
            params: {
                enabled: title === 'Enable' ? true : false,
                status: reason
            }
        }
        const response = await fetchService.availabilityStatus(payload);

        if (response) {
            setObject(prevItem => ({
                ...prevItem,
                "available": title === "Enable" ? true : false,
                "status": reason
            }))
            setIsLoading(false)
        } else {
            setContent('Error API');
            setOpenError(true)
            setIsLoading(false)
        }
    }, [object, reason, setObject])

    useEffect(() => {
        const api = async() => {
            setIsLoading(true)
            if (activeButton === 'appliances') {
                await appliances_E_D_API(title);
            }
            if (activeButton === 'availability') {
                await availability_E_D_API(title);
            }
        }
        if (isPassed) {
            api()
            setIsPassed(false)
        }
    }, [isPassed, activeButton, title, availability_E_D_API, appliances_E_D_API])
    return (
        <>
            {
                isLoading && <Loader />
            }
            <div>
                <button className='bg-slate-50 w-full px-2 py-2 md:my-1 lg:my-3 shadow-bottom click'
                    onClick={() => handleClick(name)}>
                    <img src={img} className="mx-auto" alt="img"></img>
                    <p className={`px-2 text-sm font-medium ${(name === 'Enable' || name === 'Ready') && 'text-green-500'} ${(name === 'Disable' || name === 'Undo') && 'text-red-500'}`}>{name}</p>
                </button>

            </div>
            
            {
                openActions && (
                    <Actions setOpenActions={setOpenActions} order={order} activeButton={activeButton} setNewOrders={setNewOrders} />
                )
            }
            {
                openPrint && (
                    <Print setOpenPrint={setOpenPrint} />
                )
            }
            {
                openError && (
                    <Warning setOpen={setOpenError} content={content} />
                )
            }
            {
                openKeyboard && (
                    <Keyboard setOpenKeyboard={setOpenKeyboard} setReason={setReason} setOpenPass={setOpenPass} activeButton={activeButton}/>
                )
            }
            {
                openPass && (
                    <Password setOpenPass={setOpenPass} setIsPassed={setIsPassed} setCancel={setOpenPass} />
                )
            }
            {
                openView && (
                    <ProductAvailability setOpen={setOpenView} items={object} />
                )
            }
            {
                openAdjust && (
                    <Adjust setOpen={setOpenAdjust} items={object} setItems={setObject} />
                )
            }
        </>

    )
}

export default Pattern;