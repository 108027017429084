import { useEffect, useState } from "react";

const Footer = () => {
    const [time, setTime] = useState(new Date());
    
    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, [])

    const formatTime = (date) => {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        // const seconds = date.getSeconds();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert "0" hour to "12"
        return [
            formattedHours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            // seconds.toString().padStart(2, '0'),
        ].join(':') + ' ' + ampm;
    }
    return (
        <div className="w-full flex justify-between items-center border border-gray-100 bg-[#F0F0F0] fixed bottom-0">
            <div></div>
            <div className="lg:text-xl md:text-base text-sm ">YAKUMA EXPO</div>
            <div className="lg:text-2xl md:text-lg text-sm font-bold">{formatTime(time)}</div>
        </div>
    )
}
export default Footer