import ready from '../Assets/check 1.png';
import action from '../Assets/double-tap 1.png';
import printer from '../Assets/printer 1.png';
import adjust from '../Assets/Controller.png';
import view from '../Assets/File.png';
import enable from '../Assets/Thumbs up.png';
import disable from '../Assets/Thumbs down.png';
import Pattern from './Pattern.js';
import { useEffect, useState } from 'react';

const Sidebar = ({ activeButton, object, setObject, order, setRefresh, setNewOrders }) => {
    const [detail, setDetail] = useState();
    useEffect(() => {
        if (object) {
            setDetail(object);
        }
    }, [object])

    return (
        <>
            <div className="my-4">
                {
                    activeButton === 'order' && (
                        <>
                            <Pattern img={ready} title={"Ready"} object={object} setObject={setObject} order={order}/>
                            <Pattern img={action} title={"Action"} object={object} setObject={setObject} order={order} activeButton={activeButton} setNewOrders={setNewOrders}/>
                            <Pattern img={printer} title={"Reprint"} object={object} setObject={setObject}/>
                        </>
                    )
                }
                {
                    activeButton === "inventory" && (
                        <>
                            <Pattern img={adjust} title={"Adjust"} object={object} setObject={setObject}/>
                            <Pattern img={view} title={"View"} object={detail} setObject={setObject}/>
                        </>
                    )
                }
                {
                    activeButton === "availability" && (
                        <>
                            <Pattern img={enable} title={"Enable"} object={object} setObject={setObject} activeButton={activeButton}/>
                            <Pattern img={disable} title={"Disable"} object={object} setObject={setObject} activeButton={activeButton}/>
                            <Pattern img={view} title={"View"} object={detail} setObject={setObject} />
                        </>
                    )
                }
                {
                    activeButton === "appliances" && (
                        <>
                            <Pattern img={enable} title={"Enable"} object={object} setObject={setObject}  activeButton={activeButton} />
                            <Pattern img={disable} title={"Disable"} object={object} setObject={setObject}  activeButton={activeButton} />
                        </>
                    )
                }
                {
                    activeButton === "orderhistory" && (
                        <>
                            <Pattern img={action} title={"Action"} object={object} setObject={setObject} order={order} activeButton={activeButton} setNewOrders={setNewOrders}/>
                            <Pattern img={printer} title={"Reprint"} object={object} setObject={setObject}/>
                        </>
                    )
                }
            </div>
        </>
    )
}
export default Sidebar;