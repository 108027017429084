const key = 'order';

export const storageService = {
    read: () => {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : [];
    },
    save: (payload) => {
        localStorage.setItem(key, JSON.stringify(payload));
    },
    remove: (payload) => {
        localStorage.removeItem(key);
        localStorage.setItem(key, JSON.stringify(payload));
    }
}