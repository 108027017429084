import { useState } from 'react';
import { fetchService } from '../Services/fetch.service.js';
import Loader from '../Common/Loader.js';
import Warning from './Warning.js';
import login from '../Assets/Login.svg'
import eye from '../Assets/eye.svg';

const Auth = ({ handleLogin }) => {
    const [username, setUsername] = useState('');
    const [passwords, setPasswords] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async () => {
        setIsLoading(true)

        const payload = {
            su: username,
            sp: passwords
        }

        const {token, sub_token} = await fetchService.login(payload);

        if (token) {
            handleLogin({ username, token, sub_token });
        } else {
            setIsLoading(false)
            setOpenError(true);
        }
    }
    
    return (
        <>
            {
                isLoading && (
                    <Loader />
                )
            }
            {
                openError && (
                    <Warning setOpen={setOpenError} content={"Wrong Credential"}/>
                )
            }
            <div className="bg-white h-screen w-full flex">
                <div className='w-1/2 h-full bg-[#eee] relative sm:block hidden'>
                    <img src={login} alt='login' className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'></img>
                </div>
                <div className='sm:w-1/2 w-full h-full relative'>
                    <div className='w-[80%] mx-auto absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'>
                        <p className='text-center xl:text-4xl lg:text-3xl sm:text-2xl text-3xl font-bold mb-8'>EXPO Login</p>
                        <form>
                            <p className='text-gray-500'>Email or Username</p>
                            <input name='su' className='w-full outline-none border-b border-gray-200 py-3' value={username} onChange={(e) => setUsername(e.target.value)}></input>

                            <p className='text-gray-500 mt-8'>Password</p>
                            <span className='w-full flex border-b border-gray-200'>
                                <input name='su' className='flex-grow outline-none py-3' autoComplete='new-password' type={`${showPassword ? '':'password'}`} value={passwords} onChange={(e) => setPasswords(e.target.value)}></input>
                                <img src={eye} alt='eye' className='cursor-pointer' onClick={() => {setShowPassword(!showPassword)}}></img>
                            </span>
                        </form>
                        <p className='font-medium text-end mt-8 mb-4'>Forgot Password?</p>
                        <button className='w-full bg-black active:bg-zinc-800 text-center text-white py-6' onClick={handleSubmit}>CONTINUE</button>
                    </div>
                </div>
            </div>
        </>
    )


}

export default Auth