const ProductAvailability = ({ setOpen, items }) => {
    return (
        <>
            <div className="bg-black/75 h-screen w-screen absolute top-0 left-0 z-10">
                <div className="mx-auto mt-20 pb-4 w-[70%] lg:w-[50%] bg-white">
                    <div className="px-3 py-1 bg-[#979797] text-center text-white text-2xl font-semibold">
                        Product Availability
                    </div>
                    <div className="flex justify-between p-8">
                        <div className="grid grid-cols-6 mx-2 w-[60%] text-lg">
                            <div className="col-span-2">Product</div>
                            <div className="col-span-4 font-bold">{items.name}</div>

                            <div className="col-span-2">Details</div>
                            <div className="col-span-4 font-bold break-words"></div>
                        </div>
                        <div className="grow"></div>
                        <div>
                            <button className='bg-green-500 active:bg-green-400 text-lg text-white font-medium px-5 py-2'>
                                Refresh
                            </button>
                        </div>
                    </div>
                    <div className="flex w-full justify-between h-[50vh] px-8">
                        <div className="w-[90%] border-t border-black">
                            <table className="table-fixed w-full">
                                <thead>
                                    <tr>
                                        <th className="w-[40%]">ITEM</th>
                                        <th>STATUS</th>
                                        <th className="w-[5px]"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (!!items.details) ? (
                                            (items?.details?.length) ? (
                                                items.details.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>{item.name}</td>
                                                        <td>{item.status}</td>
                                                        <td className="w-[1px] h-[1px]">
                                                            <div className={`w-5 h-full mx-auto  ${items.available ? 'bg-green-400' : 'bg-red-500'}`}></div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td>{items.details.name}</td>
                                                    <td>{items.details.status}</td>
                                                    <td className="w-[1px] h-[1px]">
                                                            <div className={`w-5 h-full mx-auto  ${items.details.available ? 'bg-green-400' : 'bg-red-500'}`}></div>
                                                    </td>
                                                </tr>
                                            )
                                        ) : (<></>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="w-full flex justify-center">
                        <button className='px-14 py-2 bg-gray-200 shadow-bottom click text-lg' onClick={() => setOpen(false)}>
                            Cancel
                        </button>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default ProductAvailability;