const Close = ({ setOpenClose, handleLogout }) => {

    const handleClose = () => {
        handleLogout('user')
    }
    return (
        <>
            <div className=" bg-black/75 h-screen w-screen absolute top-0 left-0 z-10">
                <div className="mx-auto mt-20 w-1/2 lg:w-2/5 bg-white">
                    <div className="px-3 py-1 bg-[#979797] text-white text-2xl text-center font-bold">
                        EXPO Logout
                    </div>
                    <p className="text-center mx-20 font-semibold mt-14 mb-10">Are you sure you want to logged out? Once you logged out, you need to login again. Are you okay?</p>
                    <div className="w-full px-10  text-xl">
                        <button className="bg-black text-white p-3 my-3 text-center w-full shadow-group click" onClick={handleClose}>
                            Logout
                        </button>
                        <button className="bg-gray-200 p-3 my-3 text-center w-full shadow-group click">
                            Shutdown terminal
                        </button>
                        <button className="bg-gray-200 p-3 my-3 text-center w-full shadow-group click" onClick={() => setOpenClose(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Close