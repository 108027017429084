import React, { useCallback, useEffect, useState } from "react";
import Loader from "../Common/Loader";
import ReplaceProductLists from "./ReplaceProductLists";
import Password from "./Password";
import { fetchService } from "../Services/fetch.service";

const ReplaceOrder = ({ order, setOpenReplace, setOpenActions }) => {
    const [openPass, setOpenPass] = useState(false);
    const [isPassed, setIsPassed] = useState(false);

    const [openProductsList, setOpenProductsList] = useState(false);

    const [items, setItems] = useState([]);
    const [itemIndex, setItemIndex] = useState(0);

    const [newItem, setNewItem] = useState();

    const [filter, setFilter] = useState({
        unprepared: true,
        detail: false
    });

    const handleFilter = (event) => {
        const { name, checked } = event.target;
        setFilter(prevFilter => ({
            ...prevFilter, [name]: checked
        }))
    };

    const replaceAPI = useCallback(async () => {
        const payload = {
            orderId: order.id,
            detailId: items[itemIndex].id,
            payload: newItem
        }

        const response = await fetchService.orderReplace(payload);

        if(response) {
            console.log("Sucess ORDER Replace!!!");
            setOpenReplace(false);
            setOpenActions(false);
        } else {
            console.log("Error API")
            setIsPassed(false)
        }
    }, [items, itemIndex, newItem])

    const handleConfirm = () => {
        if(newItem) {
            setOpenPass(true);
        }
    }

    useEffect(() => {
        setItems(order.rows);
    }, [order]);

    useEffect(() => {
        if(isPassed) {
            replaceAPI();
        }
    }, [isPassed])
 
    return(
        <>
            {
                isPassed ? (
                    <Loader/>
                ) : (
                    <>
                        <div className="h-screen w-screen absolute top-0 left-0 z-10">
                            <div className="mx-auto mt-20 w-[70%] lg:w-[60%] h-[500px] bg-white">
                                <div className="px-3 py-1 bg-[#979797] text-center text-2xl text-white font-bold">
                                    Replace products
                                </div>
                                <div className="p-4 w-full bg-white">
                                    <div className=" flex justify-between">
                                        <div className="grid grid-cols-6 w-[60%] xl:text-lg">
                                            <div className="col-span-2">Customer</div>
                                            <div className="col-span-4 font-bold">{order.customer_name}</div>

                                            <div className="col-span-2">Order</div>
                                            <div className="col-span-4 font-bold">{order.type} #{order.order_number}</div>

                                            <div className="col-span-2">Details</div>
                                            <div className="col-span-4 font-bold"></div>
                                        </div>

                                        <div className="xl:text-base text-xs">
                                            <div className="flex items-center m-2">
                                                <input type="checkbox" name="unprepared" className="checkbox border" checked={filter.unprepared} onChange={handleFilter}></input>
                                                <p className="px-1">Unprepared only</p>
                                            </div>
                                            <div className="flex items-center m-2">
                                                <input type="checkbox" name="detail" className="checkbox border" checked={filter.detail} onChange={handleFilter}></input>
                                                <p className="px-1">Show details</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="w-full pt-4 h-[380px] flex justify-between">
                                        {/* Table */}
                                        <div className="w-[50%] overflow-auto border-t border-black">
                                            <table className="table-fixed w-full">
                                                <thead>
                                                    <tr>
                                                        <th className="w-[5px]"></th>
                                                        <th className="w-[20%] text-center">Qty</th>
                                                        <th className="text-center">Item</th>
                                                        <th className="w-[20%] text-center">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-center">
                                                    {
                                                        items?.map((item, i) => (
                                                            <React.Fragment key={i}>
                                                                {
                                                                    (filter.unprepared ? (item?.status === 'PENDING') : true) && (
                                                                        <>
                                                                            {
                                                                                (item.type !== 'remarks') && (
                                                                                    <tr key={"replace" + i} className={`${i === itemIndex ? 'bg-black hover:bg-black text-white' : ''}`} onClick={() => setItemIndex(i)}>
                                                                                        <td className="w-[20px]">
                                                                                            {
                                                                                                (i === itemIndex) && (
                                                                                                    <div>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                                                                                            <rect width="19.1293" height="17.3075" fill="#00A600"/>
                                                                                                            <path d="M4.09961 8.97091L7.11368 11.8403L15.4861 5.46387" stroke="white" strokeWidth="1.74572"/>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </td>
                                                                                        <td>{item.quantity}</td>
                                                                                        <td>{item.name}</td>
                                                                                        <td>
                                                                                            <div className={`w-[20px] h-[20px] mx-auto ${item.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                            {
                                                                                ((filter.detail) && item?.modifiers) && (
                                                                                    (item.modifiers.length) ? (
                                                                                        item.modifiers.map((sitem, j) => (
                                                                                            <tr key={i + "-" + j} className={`${i === itemIndex ? 'bg-yellow-200' : ''}`} onClick={() => { setItemIndex(i) }}>
                                                                                                <td ></td>
                                                                                                <td>{sitem.quantity}</td>
                                                                                                <td>{sitem.name}</td>
                                                                                                <td>
                                                                                                    <div className={`w-[20px] h-[20px] mx-auto ${item.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    ) : (
                                                                                        <tr tr key={"replace1-" + i} className={`${i === itemIndex ? 'bg-yellow-200' : ''}`} onClick={() => { setItemIndex(i) }}>
                                                                                            <td></td>
                                                                                            <td>{item.modifiers.quantity}</td>
                                                                                            <td>{item.modifiers.name}</td>
                                                                                            <td>
                                                                                                <div className={`w-[20px] h-[20px] mx-auto ${item.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="w-[10%] flex justify-center border-t border-black">
                                            <button className="mx-auto" onClick={() => setOpenProductsList(true)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="53" viewBox="0 0 100 53" fill="none">
                                                    <path d="M75.9313 3.6322L95.3932 26.166L75.9313 48.6997C75.5828 49.1023 75.3878 49.6428 75.3878 50.2056C75.3878 50.7684 75.5828 51.3088 75.9313 51.7114C76.1005 51.9064 76.3025 52.0612 76.5254 52.167C76.7483 52.2727 76.9877 52.3271 77.2295 52.3271C77.4713 52.3271 77.7107 52.2727 77.9336 52.167C78.1566 52.0612 78.3586 51.9064 78.5278 51.7114L99.236 27.7394C99.5996 27.3185 99.8031 26.7538 99.8031 26.166C99.8031 25.5781 99.5996 25.0135 99.236 24.5925L78.5318 0.620483C78.3624 0.424168 78.1599 0.268131 77.9363 0.161587C77.7126 0.0550422 77.4723 0.000156311 77.2295 0.000156322C76.9868 0.000156333 76.7464 0.0550422 76.5228 0.161587C76.2991 0.268131 76.0966 0.424168 75.9273 0.620483C75.5788 1.02309 75.3838 1.56355 75.3838 2.12634C75.3838 2.68914 75.5788 3.2296 75.9273 3.6322L75.9313 3.6322Z" fill="#545454"/>
                                                    <path d="M23.8715 3.6322L4.40957 26.166L23.8715 48.6997C24.2199 49.1023 24.415 49.6428 24.415 50.2056C24.415 50.7684 24.2199 51.3088 23.8715 51.7114C23.7022 51.9064 23.5002 52.0612 23.2773 52.167C23.0544 52.2727 22.815 52.3271 22.5732 52.3271C22.3314 52.3271 22.092 52.2727 21.8691 52.167C21.6462 52.0612 21.4442 51.9064 21.275 51.7114L0.566726 27.7394C0.203159 27.3185 -0.000319317 26.7538 -0.000319291 26.166C-0.000319265 25.5781 0.203159 25.0135 0.566726 24.5925L21.271 0.620483C21.4403 0.424168 21.6428 0.268131 21.8665 0.161587C22.0901 0.0550422 22.3304 0.000156311 22.5732 0.000156322C22.816 0.000156333 23.0563 0.0550422 23.28 0.161587C23.5036 0.268131 23.7061 0.424168 23.8755 0.620483C24.2239 1.02309 24.4189 1.56355 24.4189 2.12634C24.4189 2.68914 24.2239 3.2296 23.8755 3.6322L23.8715 3.6322Z" fill="#545454"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="w-[40%] overflow-auto border-t border-l border-black">
                                            <table className="table-fixed w-full">
                                                <thead>
                                                    <tr>
                                                        <th className="w-[20%] text-center">Qty</th>
                                                        <th className="text-center">Item</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-center">
                                                    <tr>
                                                        <td>{newItem?.quantity}</td>
                                                        <td>{newItem?.name}</td>
                                                    </tr>                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="w-full mt-3 text-center flex justify-between font-semibold text-lg">
                                        <button className="bg-black px-14 py-3 shadow-bottom click" onClick={handleConfirm}>
                                            <p className={`${newItem ? 'text-white' : 'text-gray-400'}`}>Confirm</p>
                                        </button>
                                        <button className="bg-gray-200 px-14 py-3 shadow-bottom click" onClick={() => setOpenReplace(false)}>
                                            <p>Cancel</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            openPass && (
                                <Password setOpenPass={setOpenPass} setIsPassed={setIsPassed} setCancel={setOpenPass}/>
                            )
                        }
                        {
                            openProductsList && (
                                <ReplaceProductLists setOpenProductList={setOpenProductsList} setItemToReplace={setNewItem}/>
                            )
                        }
                    </>
                )
            }
        </>
    )
}

export default ReplaceOrder;