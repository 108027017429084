import { useState } from 'react';
import search from '../Assets/Magnifiying Glass.png';
import Keyboard from './Keyboard.js';

const Filter = ({ setKeyword, keyboard, title, filter, setFilter }) => {

    const [openKeyboard, setOpenKeyboard] = useState(false)

    const handleCheckboxEvent = (event) => {
        const { name, checked } = event.target;
        if (name === "all") {
            if (checked) {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    [name]: checked,
                    "disabled": false
                }))
            } else {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    [name]: checked,
                    "disabled": true
                }))
            }
        }
        if (name === "disabled") {
            if (checked) {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    [name]: checked,
                    "all": false
                }))
            } else {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    [name]: checked,
                    "all": true
                }))
            }

        }
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: checked
        }));
    }
    return (
        <div>
            <div className={`bg-gray-200 items-center justify-between xl:text-lg lg:text-base md:text-sm text-xs ${title === 'order' ? 'xl:flex block' : 'flex'}`}>
                <div className='flex'>
                    <div className='flex justify-between w-full'>
                        <span className='ml-4 mr-2 py-3'>View</span>
                        {
                            (title === 'order' || title === 'orderhistory') && (
                                <>
                                    <div className='flex items-center xl:mx-7 lg:mx-2 md:mx-6'>
                                        <input type='checkbox' name='local' className={`${filter.local && 'local'} checkbox`} checked={filter.local} onChange={handleCheckboxEvent}></input>
                                        <span className='mx-2'>Local</span>
                                    </div>
                                    <div className='flex items-center xl:mx-7 lg:mx-2 md:mx-6'>
                                        <input type='checkbox' name='app' className={`${filter.app && 'app'} checkbox`} checked={filter.app} onChange={handleCheckboxEvent}></input>
                                        <span className='mx-2'>App</span>
                                    </div>
                                    <div className='flex items-center xl:mx-7 lg:mx-2 md:mx-6'>
                                        <input type='checkbox' name='delivery' className={`${filter.delivery && 'delivery'} checkbox`} checked={filter.delivery} onChange={handleCheckboxEvent}></input>
                                        <span className='mx-2'>Delivery</span>
                                    </div>
                                    <div className='flex items-center xl:mx-7 lg:mx-2 md:mx-6'>
                                        <input type='checkbox' name='details' className='checkbox' checked={filter.details} onChange={handleCheckboxEvent}></input>
                                        <span className='mx-2'>Show details</span>
                                    </div>
                                    {
                                        title === 'order' && (
                                            <div className='flex items-center xl:mx-7 lg:mx-2 md:mx-6'>
                                                <input type='checkbox' name='unprepared' className='checkbox' checked={filter.unprepared} onChange={handleCheckboxEvent}></input>
                                                <span className='mx-2'>Unprepared only</span>
                                            </div>
                                        )
                                    }
                                </>
                            )
                        }
                        {
                            (title !== 'order' && title !== 'orderhistory') && (
                                <>
                                    <div className='flex items-center xl:mx-7 lg:mx-2 md:mx-6'>
                                        <input type='checkbox' name='all' className='checkbox' checked={filter.all} onChange={handleCheckboxEvent}></input>
                                        <span className='mx-2'>All</span>
                                    </div>
                                    <div className='flex items-center xl:mx-7 lg:mx-2 md:mx-6'>
                                        <input type='checkbox' name='disabled' className='checkbox' checked={filter.disabled} onChange={handleCheckboxEvent}></input>
                                        <span className='mx-2'>Disabled / alerts</span>
                                    </div>
                                </>
                            )
                        }
                        {
                            title === 'inventory' && (
                                <>
                                    <div className='flex items-center xl:mx-7 lg:mx-2 md:mx-6'>
                                        <input type='checkbox' name='products' className='checkbox' checked={filter.products} onChange={handleCheckboxEvent}></input>
                                        <span className='mx-2'>Products</span>
                                    </div>
                                    <div className='flex items-center xl:mx-7 lg:mx-2 md:mx-6'>
                                        <input type='checkbox' name='ingredients' className='checkbox' checked={filter.ingredients} onChange={handleCheckboxEvent}></input>
                                        <span className='mx-2'>Ingredients</span>
                                    </div>
                                </>
                            )
                        }
                        {
                            title === 'availability' && (
                                <>
                                    <div className='flex items-center xl:mx-7 lg:mx-2 md:mx-6'>
                                        <input type='checkbox' name='products' className='checkbox' checked={filter.mdisabled} onChange={handleCheckboxEvent}></input>
                                        <span className='mx-2'>Only manually disabled</span>
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>

                <div className='flex items-center my-2 justify-center'>
                    <button className='mx-2' onClick={() => setOpenKeyboard(!openKeyboard)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="69" height="42" viewBox="0 0 69 42" fill="none">
                            <path d="M34.5 14H28.75V11.2H34.5V14ZM25.875 11.2H20.125V14H25.875V11.2ZM43.125 11.2H37.375V14H43.125V11.2ZM51.75 11.2H46V14H51.75V11.2ZM40.25 16.8H34.5V19.6H40.25V16.8ZM48.875 16.8H43.125V19.6H48.875V16.8ZM31.625 16.8H25.875V19.6H31.625V16.8ZM37.375 22.4H31.625V25.2H37.375V22.4ZM28.75 22.4H23V25.2H28.75V22.4ZM46 22.4H40.25V25.2H46V22.4ZM8.625 30.8H17.25V28H8.625V30.8ZM23 16.8H17.25V19.6H23V16.8ZM51.75 30.8H60.375V28H51.75V30.8ZM54.625 14H60.375V11.2H54.625V14ZM69 4.2V37.8C68.9985 38.9135 68.5436 39.9809 67.7352 40.7682C66.9268 41.5555 65.8308 41.9985 64.6875 42H4.3125C3.16922 41.9985 2.0732 41.5555 1.26478 40.7682C0.456361 39.9809 0.00152133 38.9135 0 37.8V4.2C0.00152133 3.08655 0.456361 2.01912 1.26478 1.23179C2.0732 0.444456 3.16922 0.00148164 4.3125 0H64.6875C65.8308 0.00148164 66.9268 0.444456 67.7352 1.23179C68.5436 2.01912 68.9985 3.08655 69 4.2ZM66.125 4.2C66.125 3.8287 65.9735 3.4726 65.704 3.21005C65.4344 2.9475 65.0687 2.8 64.6875 2.8H4.3125C3.93125 2.8 3.56562 2.9475 3.29603 3.21005C3.02645 3.4726 2.875 3.8287 2.875 4.2V37.8C2.875 38.1713 3.02645 38.5274 3.29603 38.79C3.56562 39.0525 3.93125 39.2 4.3125 39.2H64.6875C65.0687 39.2 65.4344 39.0525 65.704 38.79C65.9735 38.5274 66.125 38.1713 66.125 37.8V4.2ZM20.125 22.4H8.625V25.2H20.125V22.4ZM51.75 19.6H60.375V16.8H51.75V19.6ZM14.375 16.8H8.625V19.6H14.375V16.8ZM17.25 11.2H8.625V14H17.25V11.2ZM20.125 30.8H48.875V28H34.5H20.125V30.8ZM48.875 25.2H60.375V22.4H48.875V25.2Z" fill="#969697"/>
                        </svg>
                    </button>
                    <div className='mx-4 bg-gray-200 flex items-center border-2 border-gray-400'>
                        <input className='bg-gray-200 outline-0 px-2 py-1 w-full'
                            onChange={(e) => setKeyword(e.target.value)} value={keyboard} placeholder='Search'
                        ></input>
                        <img src={search} alt='search' className='mx-2'></img>
                    </div>
                </div>
                        
            </div>
            {
                openKeyboard && (
                    <Keyboard setKeyword={setKeyword} />
                )
            }
        </div>

    )
}
export default Filter