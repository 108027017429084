import { useState } from "react";

const Print = ({ setOpenPrint }) => {
    const [options, setOptions] = useState({
        stickers: true,
        kitchen: true,
        products: true,
        all: false
    });

    const handleCheckboxEvent = (event) => {
        const { name, checked } = event.target;
        ((name === 'all') && checked) && (
            setOptions({
                stickers: false,
                kitchen: false,
                products: false
            })
        );
        ((name !== 'all') && checked) && (
            setOptions(prevOption => ({
                ...prevOption,
                all: false
            }))
        )
        setOptions(prevOption => ({
            ...prevOption,
            [name]: checked
        }))
    }
    return (
        <>
            <div className=" bg-black/75 h-screen w-screen absolute top-0 left-0 z-10">
                <div className="mx-auto mt-20 w-[40%] bg-white font-bold">
                    <div className="px-3 py-1 bg-[#979797] text-center text-white text-2xl font-semibold">
                        Print Options
                    </div>
                    <div className="px-8 py-1">
                        <div className="flex items-center bg-zinc-200 my-6 p-3">
                            <input type="checkbox" name="stickers" className="checkbox" checked={options.stickers} onChange={handleCheckboxEvent}></input>
                            <div className="px-2">Stickers</div>
                        </div>
                        <div className="flex items-center bg-zinc-200 my-6 p-3">
                            <input type="checkbox" name="kitchen" className="checkbox" checked={options.kitchen} onChange={handleCheckboxEvent}></input>
                            <div className="px-2">Kitchen</div>
                        </div>
                        <div className="flex items-center bg-zinc-200 my-6 p-3">
                            <input type="checkbox" name="products" className="checkbox" checked={options.products} onChange={handleCheckboxEvent}></input>
                            <div className="px-2">Expo products</div>
                        </div>
                        <div className="flex items-center p-3 my-6">
                            <input type="checkbox" name="all" className="checkbox border" checked={options.all} onChange={handleCheckboxEvent}></input>
                            <div className="px-2">Expo (full order)</div>
                        </div>
                        <div className="flex justify-between">
                            <button className="py-2 px-14 my-1 bg-black text-white text-center shadow-bottom click">
                                Print
                            </button>
                            <div className="flex-grow"></div>
                            <button className="py-2 px-14 my-1 bg-zinc-200 shadow-bottom click" onClick={() => setOpenPrint(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>

                    
                </div>
            </div>
        </>
    )
}
export default Print;