import { useEffect, useRef, useState } from "react";
import Filter from "../Common/Filter.js";
import Sidebar from "../Common/Sidebar.js";
import { fetchService } from "../Services/fetch.service.js";
import getQuantity from "../Utils/getQuantity.js";
import getCompleted from "../Utils/getCompleted.js";
import React from "react";
import Loader from "../Common/Loader.js";

const OrderHistory = ({ activeButton, setNewOrders, connectionSystem }) => {
    const [selectedRow, setSelectedRow] = useState(0);

    const [orderHistories, setOrderHistories] = useState([]);
    const [orderHistoryDetails, setOrderHistoryDetails] = useState();

    const [isLoading, setIsLoading] = useState(true);

    const [filter, setFilter] = useState({
        local: true,
        app: true,
        delivery: true,
        details: false,
    });
    const [searchKey, setSearchKey] = useState('')

    const handleOrderHistory = (order, index) => {
        setSelectedRow(index);
        setOrderHistoryDetails(order.rows);
    }

    useEffect(() => {

        const fetchOrderHistory = async () => {
            const response = await fetchService.orderHistory();
            setIsLoading(false)
            setOrderHistories(response.data);
        }

        if (connectionSystem === 'enable') {
            fetchOrderHistory();
        } else {
            setIsLoading(false)
        }
    }, [connectionSystem]);

    const orderRef = useRef(null);
    const detailRef = useRef(null);

    const scrollTable = (scrollOffset, direction) => {
        if (orderRef.current) {
            if (!direction) {
                orderRef.current.scrollTop += scrollOffset;
            } else {
                orderRef.current.scrollTop -= scrollOffset;
            }
        }

    };
    const scrollDetailTable = (scrollOffset, direction) => {
        if (detailRef.current) {
            if (!direction) {
                detailRef.current.scrollTop += scrollOffset;
            } else {
                detailRef.current.scrollTop -= scrollOffset;
            }
        }

    };

    return (
        <div className="h-full">
            {
                isLoading && (
                    <Loader />
                )
            }
            <Filter title={"orderhistory"} filter={filter} setFilter={setFilter} setKeyword={setSearchKey}/>

            <div className="flex justify-between h-full shadow-bottom pt-2">
                <div className="overflow-auto h-full bg-white border-t border-r border-black" ref={orderRef}>
                    <table className="table-fixed w-full">
                        <thead>
                            <tr className="lg:text-xl md:text-base text-sm">
                                <th className="w-[10%] min-w-12">Order</th>
                                <th className="w-[15%] min-w-12">Placed at</th>
                                <th className="w-[15%] min-w-13">Deliver at</th>
                                <th className="w-[50%] min-w-12 text-center">Status</th>
                                <th className="w-[10%] min-w-12 text-center">Qty</th>
                                <th className="w-[10%] min-w-12 text-center">Done</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderHistories.map((order, i) => (
                                    <tr key={i} className={`${i === selectedRow ? 'bg-black hover:bg-black text-white' : ''}`} onClick={() => { handleOrderHistory(order, i) }}>
                                        {
                                            ((filter.local && (order.type === 'local')) || (filter.app && (order.type === 'app')) || (filter.delivery && (order.type === 'delivery'))) && (searchKey ? (!!order.customer_name.toLowerCase().includes(searchKey.toLowerCase()) || !!order.customer_phone.includes(searchKey) || !!order.order_number.toString().includes(searchKey) || !!order.customer_email.toLowerCase().includes(searchKey.toLowerCase())): true ) && (
                                                <>
                                                    <td><span className={`px-2 ${(order.type === 'local') && 'local text-white'} ${(order.type === 'app') && 'app text-white'} ${(order.type === 'delivery') && 'delivery text-black'}`}>{order.order_number}</span></td>
                                                    <td>{order.placed_at}</td>
                                                    <td>{order.must_be_delivered_at}</td>
                                                    <td>{order.customer_name}</td>
                                                    <td className="text-center">{getQuantity(order.rows)}</td>
                                                    <td className="bg-white text-center"><div className={`${i === selectedRow ? 'bg-green-500':'bg-red-500'} lg:mx-5 md:mx-4 mx-2 text-white`}>{getCompleted(order.rows)}</div></td>
                                                </>
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                <div className="overflow-auto min-w-[30%] h-full bg-white ps-6 border-t border-black" ref={detailRef}>
                    <table className="w-full">
                        <thead>
                            <tr className="lg:text-xl md:text-base text-sm">
                                <th className="w-[90%] text-center">Item</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderHistoryDetails?.map((orderDetail, i) => (
                                    <React.Fragment key={i}>
                                        {
                                            (orderDetail.type !== 'remarks') && (
                                                <tr key={"order-history-" + i}>
                                                    <td>{orderDetail.name}</td>
                                                    <td className="w-[1px] h-[1px]">
                                                        <div className={`w-6 mx-auto h-full  ${orderDetail.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        {
                                            ((filter.details === true) && orderDetail?.modifiers) && (
                                                (orderDetail.modifiers.length) ? (
                                                    orderDetail.modifiers?.map((item, j) => (
                                                        <tr key={"order-history-" + i + "-" + j} className=" bg-yellow-200">
                                                            <td className="lg:ps-5 ps-3">{item.name}</td>
                                                            <td className="w-[1px] h-[1px]">
                                                                <div className={`w-6 mx-auto h-full  ${orderDetail.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                            </td>
                                                        </tr>
                                                    ))

                                                ) : (
                                                    <tr key={"order-history1-" + i} className=" bg-yellow-200">
                                                        <td className="lg:ps-5 ps-3">{orderDetail.modifiers.name}</td>
                                                        <td className="w-[1px] h-[1px]">
                                                            <div className={`w-6 mx-auto h-full  ${orderDetail.status === 'PENDING' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                        </td>
                                                    </tr>
                                                )

                                            )
                                        }
                                    </React.Fragment>

                                ))
                            }
                        </tbody>
                    </table>
                </div>

                <Sidebar activeButton={activeButton} onScroll={scrollDetailTable} order={orderHistories[selectedRow]} setNewOrders={setNewOrders} object={orderHistoryDetails} setObject={setOrderHistoryDetails} />
            </div>

        </div>
    )
}
export default OrderHistory;