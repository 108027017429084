import refreshIcon from '../Assets/Refresh.png';
import setupIcon from '../Assets/Setting.png';
import closeIcon from '../Assets/Exit.png';
import { useEffect, useRef, useState } from 'react';
import Status from '../Modals/Status.js';
import Alerts from '../Modals/Alerts.js';
import { fetchService } from '../Services/fetch.service.js';
import Close from '../Modals/Close.js';

const Header = ({ connectionSystem, setConnectionSystem, total, handleLogout }) => {
    const [openStatus, setOpenStatus] = useState(false);
    const [openAlerts, setOpenAlerts] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [unread, setUnread] = useState();
    const [openClose, setOpenClose] = useState(false);

    const fetchAlerts = async () => {
        const response = await fetchService.alert();
        setAlerts(response);
        let unread = 0;
        if (response) {
            for (let i = 0; i < response.length; i++) {
                if (!response[i].read) {
                    unread++
                }
            }
        }
        setUnread(unread)
    }
    const hasRun = useRef(false);  // prevent twice run useEffect

    useEffect(() => {
        if (!hasRun.current) {
            // Code to run on mount
            fetchAlerts();
            hasRun.current = true;
        }
    }, []);

    return (
        <>
            <div className="sm:block md:flex justify-between ">
                <div className="flex items-center">
                    
                    <div className="mx-2 text-start md:text-xl lg:text-3xl">
                        <p>Total queue <span className='font-extrabold'>{total.app + total.dlvy + total.local} ({total.app} App - {total.dlvy} Dlvy - {total.local} Local) </span> </p>
                        <p className='lg:text-2xl md:text-base font-light'>Demo Main 3 0003</p>
                    </div>
                </div>

                <div className="flex items-center px-2 py-2 my-2 ">
                    <button className={`${(connectionSystem === 'enable' && 'bg-green-600 border-green-600') || (connectionSystem === 'disable' && 'bg-red-600 border-red-600') || (connectionSystem === 'break' && 'bg-yellow-300 border-red-300')} px-6 py-1 mx-2 my-2 border-2 lg:text-lg text-white`}
                        onClick={() => setOpenStatus(true)}>
                        <p>Status</p>
                    </button>
                    <button className="relative px-6 py-1 mx-2 my-2 lg:text-lg border-2 border-red-500 text-red-500"
                        onClick={() => setOpenAlerts(true)}>
                        <p>Alerts</p>
                        {
                            unread !== 0 && (
                                <div className='absolute -top-2 -right-2 w-5 h-5 text-xs text-white bg-red-500 rounded-[50%]'>{unread}</div>
                            )
                        }
                    </button>
                    <button className='flex items-center px-3 py-2 mx-2'>
                        <img src={setupIcon} style={{ width: '30px' }} alt='setup'></img>
                    </button>
                    <button className='flex items-center px-3 py-2 mx-2' onClick={() => { window.location.reload() }}>
                        <img src={refreshIcon} style={{ width: '30px' }} alt='refresh'></img>
                    </button>
                    <button className='flex items-center px-3 py-2 mx-2' onClick={() => setOpenClose(true)}>
                        <img src={closeIcon} style={{ width: '30px' }} alt='close'></img>
                    </button>
                </div>
            </div>
            {
                openStatus && (
                    <Status setOpenStatus={setOpenStatus} connectionSystem={connectionSystem} setConnectionSystem={setConnectionSystem} />
                )
            }
            {
                openAlerts && (
                    <Alerts setOpenAlerts={setOpenAlerts} alerts={alerts} setAlerts={setAlerts} />
                )
            }
            {
                openClose && (
                    <Close setOpenClose={setOpenClose} handleLogout={handleLogout}/>
                )
            }
        </>
    )
}
export default Header
