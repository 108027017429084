import { useEffect, useState, useCallback } from 'react';
import Keyboards from "./Keyboard.js";
import Password from "./Password.js";
import { fetchService } from "../Services/fetch.service.js";
import Loader from "../Common/Loader.js";
import RemakeOrder from './RemakeOrder.js';
import ReplaceOrder from './ReplaceOrder.js';

const Actions = ({ activeButton, setOpenActions, order, setNewOrders }) => {
    const [openRemake, setOpenRemake] = useState(false);
    const [openReplace, setOpenReplace] = useState(false);

    const [openRefund, setOpenRefund] = useState(false);
    const [reason, setReason] = useState('');
    const [openPass, setOpenPass] = useState(false);
    const [isPassed, setIsPassed] = useState(false);

    const handleRemake = () => {
        setOpenRemake(true);
    }

    const handleReplace = () => {
        setOpenReplace(true);
    }

    const handleRefund = () => {
        setOpenRefund(true);
    }
    const refundAPI = useCallback(async () => {
        const payload = {
            orderId: order.order_number,
            detailId: order.rows[0].id,
            params: {
                reason: reason
            }
        }
        const response = await fetchService.orderRefund(payload);

        if (response) {
            setOpenActions(false); //passed
        }
    }, [order, reason, setOpenActions])
    useEffect(() => {
        if (isPassed) {
            refundAPI();
        }
    }, [isPassed, refundAPI])
    return (
        <>
            {
                isPassed ? (
                    <Loader />
                ) : (
                    <>
                        <div className=" bg-black/75 h-screen w-screen absolute top-0 left-0 z-10">
                            <div className="mx-auto mt-20 w-[70%] lg:w-[50%] bg-white">
                                <div className="px-3 py-1 bg-[#979797] text-center text-white text-2xl font-bold">
                                    Order Actions
                                </div>
                                <div className="m-8 p-3">
                                    <div className=" pb-5 grid grid-cols-6 text-lg">
                                        <div className="col-span-2">Customer</div>
                                        <div className="col-span-4 font-bold">{order.customer_name} {order.customer_phone}</div>

                                        <div className="col-span-2">Order</div>
                                        <div className="col-span-4 font-bold">{order.type} #{order.order_number}</div>

                                        <div className="col-span-2">Details</div>
                                        <div className="col-span-4 font-bold"></div>
                                    </div> 
                                    <button className="w-full p-3 bg-black text-white text-lg font-semibold shadow-bottom click" onClick={handleRemake}>
                                        Remake
                                    </button>
                                    {
                                        activeButton === 'order' ? (
                                            <>
                                                <button className="w-full p-3 bg-gray-300 my-4 lg:text-lg font-bold shadow-bottom click"
                                                    onClick={handleReplace}>
                                                    Replace Product
                                                </button>

                                                <button className="w-full p-3 bg-gray-300 my-4 lg:text-lg font-bold shadow-bottom click"
                                                    onClick={handleRefund}>
                                                    Refund / Cancel
                                                </button>
                                            </>
                                        ) : (<></>)
                                    }
                                    <button className="flex justify-center my-4 w-full font-bold"
                                        onClick={() => setOpenActions(false)}>
                                        <div className="px-14 py-3 bg-gray-300 shadow-bottom click">
                                            Cancel
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            openRemake && (
                                <RemakeOrder order={order} setOpenRemake={setOpenRemake} setOpenActions={setOpenActions}/>
                            )
                        }
                        {
                            openReplace && (
                                <ReplaceOrder order={order} setOpenReplace={setOpenReplace} setOpenActions={setOpenActions}/>
                            )
                        }
                        {
                            openRefund && (
                                <Keyboards setOpenKeyboard={setOpenRefund} setReason={setReason} setOpenPass={setOpenPass} />
                            )
                        }
                        {
                            openPass && (
                                <Password setOpenPass={setOpenPass} setIsPassed={setIsPassed} setCancel={setOpenPass} />
                            )
                        }
                    </>
                )
            }

        </>
    )
}

export default Actions;