import { useEffect, useRef, useState } from "react";
import Filter from "../Common/Filter.js";
import Footer from "../Common/Footer.js";
import Sidebar from "../Common/Sidebar.js";
import { fetchService } from "../Services/fetch.service.js";
import Loader from "../Common/Loader.js";

const Inventory = ({ activeButton, connectionSystem }) => {
    const [searchkey, setSearchKey] = useState('');
    const [selectedRow, setSelectedRow] = useState();
    const [inventorys, setInventorys] = useState([]);
    const [inventory, setInventory] = useState();
    const [refresh, setRefresh] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const [filter, setFilter] = useState({
        all: true,
        disabled: false,
        products: true,
        ingredients: true,
    });

    const handleClickInventory = (inventory, index) => {
        setSelectedRow(index);
        setInventory(inventory);
    }

    useEffect(() => {
        const fetchInventory = async () => {
            const response = await fetchService.inventory();
            setIsLoading(false)
            setInventorys(response.products);
        }
        if (connectionSystem === 'enable') {
            fetchInventory();
        } else {
            setIsLoading(false)
        }
    }, [refresh, connectionSystem]);

    useEffect(() => {
        if (inventory) {
            setInventorys(prev => {
                const temp = prev;
                temp[selectedRow] = inventory;
                return [...temp];
            })
        }
    }, [inventory, selectedRow])

    const tableRef = useRef();
    const scrollTable = (scrollOffset, direction) => {
        if (tableRef.current) {
            if (!direction) {
                tableRef.current.scrollTop += scrollOffset;
            } else {
                tableRef.current.scrollTop -= scrollOffset;
            }
        }

    };

    return (
        <div className="h-full">
            {
                isLoading && (
                    <Loader/>
                )
            }
            <Filter setKeyword={setSearchKey} keyboard={searchkey} title={"inventory"} filter={filter} setFilter={setFilter} />

            <div className="flex justify-between h-full">
                <div className="overflow-auto w-[90%] h-full border-t border-black mt-2" ref={tableRef}>
                    <table className="table-fixed w-full">
                        <thead>
                            <tr>
                                <th className="w-[60%] text-center">Items</th>
                                <th className="w-[20%] text-center">Queue</th>
                                <th className="w-[30%] text-center">Stock</th>
                                <th className="w-[10%] text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                inventorys?.map((inventory, i) => (
                                    ((filter.products && (inventory.type === 'product')) || (filter.ingredients && (inventory.type === 'ingredient'))) && (filter.disabled ? inventory.available === false : true) && (searchkey ? !!inventory.name.toLowerCase().includes(searchkey.toLowerCase()) : true) && (
                                        <tr key={i} className={`${i === selectedRow ? 'bg-black hover:bg-black text-white' : ''}`} onClick={() => { handleClickInventory(inventory, i) }}>
                                            <td className="lg:pl-10 md:pl-6 pl-2">{inventory.name}</td>
                                            <td className="text-center">0</td>
                                            <td className="text-center">{inventory.quantity}</td>
                                            <td className="w-full h-[1px] bg-white">
                                                <div className={`${inventory.available === true ? 'bg-green-400' : 'bg-red-500'} w-6 mx-auto h-full`}></div>
                                            </td>
                                        </tr>
                                    )
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                <Sidebar activeButton={activeButton} onScroll={scrollTable} object={inventory} setObject={setInventory} setRefresh={setRefresh} />
            </div>

            <Footer />
        </div>
    )
}
export default Inventory;