const parseCookieValue = (cookieName) => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        cookie = cookie.trim();
        if (cookie.startsWith(`${cookieName}=`)) {
            const cookieValue = cookie.substring(cookieName.length + 1);
            const decodedValue = decodeURIComponent(cookieValue);
            return JSON.parse(decodedValue);
        }
    }
    return null;
}

export default parseCookieValue;