import axios from "axios";
import parseCookieValue from "../Utils/parseCookieValue.js";

const baseURL = process.env.REACT_APP_URL;
const userCookieData = parseCookieValue("user");

export const fetchService = {
  internetConnection: async () => {
    try {
      const response = await axios.get(`${baseURL}/connection`, {
        headers: {
          "Cache-Control": "no-cache", // Add cache control header to disable caching
        },
      });
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("No internet", error);
      return false;
    }
  },
  alert: async () => {
    try {
      if(userCookieData !== null && userCookieData.username){
        const username = userCookieData.username;
        const token = userCookieData.token;

        const response = await axios.post(`${baseURL}/alerts`, {
          username,
          token,
        });
        if (response.status === 200) {
          return response.data.data.alerts;
        } else {
          return false;
        }
      }
      
    } catch (error) {
      console.error("Error during API request:", error);
      return false;
    }
  },
  appliance: async () => {
    try {
      const username = userCookieData.username;
      const token = userCookieData.token;

      const response = await axios.post(`${baseURL}/appliances`, {
        username,
        token,
      });
      if (response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error during API request:", error);
      return false;
    }
  },
  applianceStatus: async (payload) => {
    try {
      const id = payload.id;
      const params = payload.params;

      const username = userCookieData.username;
      const token = userCookieData.token;

      const response = await axios.post(`${baseURL}/appliancesstatus`, {
        username,
        token,
        id,
        params,
      });

      if (response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error during API request:", error);
      return false;
    }
  },
  availability: async () => {
    try {
      const username = userCookieData.username;
      const token = userCookieData.token;

      const response = await axios.post(`${baseURL}/availability`, {
        username,
        token,
      });

      if (response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error during API request:", error);
      return false;
    }
  },
  availabilityStatus: async (payload) => {
    try {
      const username = userCookieData.username;
      const token = userCookieData.token;

      const id = payload.id;
      const params = payload.params;

      const response = await axios.post(`${baseURL}/availabilitystatus`, {
        username,
        token,
        id,
        params,
      });

      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error during API request:", error);
    }
  },
  inventory: async () => {
    try {
      const username = userCookieData.username;
      const token = userCookieData.token;
      const response = await axios.post(`${baseURL}/products/inventory`, {
        username,
        token,
      });

      if (response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  inventoryUpdate: async (payload) => {
    try {
      const username = userCookieData.username;
      const token = userCookieData.token;

      const id = payload.id;
      const params = payload.params;

      const response = await axios.post(`${baseURL}/products/inventoryupdate`, {
        username,
        token,
        id,
        params,
      });

      if (response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  login: async (payload) => {
    try {
      const username = payload.su;
      const password = payload.sp;

      const response = await axios.post(`${baseURL}/login`, {
        username,
        password,
      });

      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false; // fix this as false after correct data api
    }
  },
  login_initial: async (payload) => {
    try {
      const username = payload.su;
      const token = payload.sp;

      const response = await axios.post(`${baseURL}/login_initial`, {
        username,
        token,
      });

      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  pincode: async (payload) => {
    try {
      const sub_token = payload.sub_token;
      const input = payload.input;

      const response = await axios.post(`${baseURL}/pincode`, {
        sub_token,
        input,
      });
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  orderLive: async (payload) => {
    try {
      const username = payload.su;
      const token = payload.sp;
      const response = await axios.post(`${baseURL}/orders/live`, {
        username,
        token,
      });

      if (response.status === 200) {
        return response.data.orders;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error during API request:", error);
      return false;
    }
  },
  orderCompleted: async (payload) => {
    try {
      const username = userCookieData.username;
      const token = userCookieData.token;
      const id = payload;
      const params = {
        time_utc: new Date(),
        status: 'READY'
      };
      const response = await axios.post(`${baseURL}/orders/completed`, {
        username,
        token,
        id,
        params,
      });

      if (response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  orderHistory: async () => {
    try {
      const username = userCookieData.username;
      const token = userCookieData.token;
      const response = await axios.post(`${baseURL}/orders/historical`, {
        username,
        token,
      });

      if (response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error during API request:", error);
      return false;
    }
  },
  orderRemake: async (payloads) => {
    try {
      const username = userCookieData.username;
      const token = userCookieData.token;
      const orderId = payloads.orderId;
      const detailId = payloads.detailId;
      const payload = payloads.payload;
      const response = await axios.post(`${baseURL}/orders/remake`, {
        username,
        token,
        orderId,
        detailId,
        payload,
      });

      if (response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error during API request:", error);
      return false;
    }
  },
  orderReplace: async (payloads) => {
    try {
      const username = userCookieData.username;
      const token = userCookieData.token;
      const orderId = payloads.orderId;
      const detailId = payloads.detailId;
      const params = payloads.params;
      const payload = payloads.payload;
      const response = await axios.post(`${baseURL}/orders/replace`, {
        username,
        token,
        orderId,
        detailId,
        params,
        payload,
      });

      if (response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error during API request:", error);
      return false;
    }
  },
  orderRefund: async (payload) => {
    try {
      const username = userCookieData.username;
      const token = userCookieData.token;

      const orderId = payload.orderId;
      const detailId = payload.detailId;
      const params = payload.params;

      const response = await axios.post(`${baseURL}/orders/refund`, {
        username,
        token,
        orderId,
        detailId,
        params,
      });

      if (response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error during API request:", error);
      return false;
    }
  },
  productList: async () => {
    try{
      const username = userCookieData.username;
      const token = userCookieData.token;

      const response  = await axios.post(`${baseURL}/products`, {
        username,
        token
      })

      if(response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error during API request:", error)
      return false;
    }
  },
  systemStatus: async (payload) => {
    try {
      const username = userCookieData.username;
      const token = userCookieData.token;
      const params = payload;
      const response = await axios.post(`${baseURL}/status/online-ordering`, {
        username,
        token,
        params,
      });

      if (response.status === 200) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error during API request:", error);
      return false;
    }
  },
};
